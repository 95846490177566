import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { civilites, sexes, etatCivil, districts, langues, statusOFAS } from '../Constants'
import { ValidationObserver } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import ImportantAppointmentInfo from '@/components/shared/ImportantAppointmentInfo/ImportantAppointmentInfo.vue'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    Alert,
    ValidationObserver,
    ImportantAppointmentInfo
  }
})
export default class InfoPage extends Vue {
  public infoPageRequest: any = {}
  public civilites = civilites
  public sexes = sexes
  public etatCivil = etatCivil
  public districts = districts
  public langues = langues
  public languesCorrespondance = langues
  public statusOFAS = statusOFAS

  @Prop() dossier!: any

  public mounted () {
    this.updateDossier()
  }

  private updateDossier () {
    if (this.dossier?.guid) {
      this.infoPageRequest = this.dossier
      this.infoPageRequest.languagesEnumIdList = Commons.transformLanguages(this.infoPageRequest.languagesEnumIdList)
    }
  }

  public get isEditMode () {
    return this.dossier?.guid
  }

  @Watch('dossier') changed () {
    this.updateDossier()
    this.infoPageRequest.guid = this.dossier.guid
    this.infoPageRequest.statusEnumId = this.dossier.statusEnumId
    this.infoPageRequest.patientCode = this.dossier.patientCode
  }

  get dossierID () {
    return this.dossier.guid
  }

  public async Save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.$emit('save', this.infoPageRequest)
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public Close () {
    this.$emit('close')
  }
}
