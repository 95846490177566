import { AbsenceModel, AppUser } from "@/models/app-user-dto"
import { RoomModel, RoomPlanningPeriodModel } from "@/models/rooms-model"
import { TextValue } from "./common-models"
import { mappingTypeDeSoin2PrestationCategory, mappingJSDate2ourWeekDays } from '@/views/Patients/Dossier/Constants'
import { PrestationAgendaResponseModel } from "@/models/prescriptions-model"
import { AgendaDialogFormModel, AgendaEvent, AppointmentReqModel, AppointmentResponseModel } from "@/models/agenda-model"

export default class AgendaHelpers {
  public static DefaultAgendaFormState (e: AgendaEvent): AgendaDialogFormModel {
    return {
      description: '',
      duration: 0,
      event: e,
      importantInfo: '',
      intervenant: '',
      otherLocation: '',
      patient: undefined,
      prestation: '',
      soinTypeSelected: undefined,
      theDate: null,
      timeEnd: null,
      timeStart: null,
      type: 1,
      roomId: undefined
    }
  }

  public static DefaultAppointmentResponseModel (): AppointmentResponseModel {
    return {
      id: 0,
      dossierId: '',
      nurseId: '',
      prestationId: '',
      roomId: 0,
      statusId: 0,
      appointmentTypeId: 1,
      consultationTypeId: 0,
      importantInfo: '',
      description: '',
      otherLocation: '',
      start: '',
      end: '',
      cancelReason: ''
    }
  }

  public static GenerateAppointmentReqModel (formState: AgendaDialogFormModel, event: AgendaEvent): AppointmentReqModel {
    const isConsultation = AgendaHelpers.IsConsultationAppointmentType(formState)
    const isOther = AgendaHelpers.IsOtherAppointmentType(formState)
    return {
      id: event.id,
      dossierId: isConsultation ? formState.patient?.guid : undefined,
      nurseId: formState.intervenant,
      prestationId: isConsultation ? formState.prestation : undefined,
      roomId: isConsultation ? formState.roomId : undefined,
      statusId: 1, // definitif by default
      appointmentTypeId: formState.type,
      consultationTypeId: isOther ? 0 : formState.soinTypeSelected,
      importantInfo: isConsultation ? formState.importantInfo : '',
      description: formState.description,
      otherLocation: isOther ? formState.otherLocation : '',
      date: formState.theDate!,
      startTime: formState.timeStart!,
      endTime: formState.timeEnd!,
      isFixed: isOther ? formState.isFixed : undefined
    }
  }

  public static IsOtherAppointmentTypeValue (type: number) {
    return type === 2
  }

  public static IsOtherAppointmentType (formState: AgendaDialogFormModel) {
    return AgendaHelpers.IsOtherAppointmentTypeValue(formState.type)
  }

  public static IsConsultationAppointmentType (formState: AgendaDialogFormModel) {
    return formState.type === 1
  }

  public static FilterRoomsBySite (currentSiteId: number, allRooms: RoomModel[]) {
    return allRooms.filter((r) => r.siteId === currentSiteId) as RoomModel[]
  }

  public static FilterRoomsByInfirmiere (infirmiere: AppUser, allRooms: RoomModel[]) {
    return allRooms.filter((r) => r.soinsTypeIds?.some(s => infirmiere.soinsTypeIds.includes(s)))
  }

  public static FilterSitesByRooms (usableRooms: RoomModel[], allSite: { site: string; siteId: number }[]) {
    return allSite.filter((s) => usableRooms.some((r) => r.siteId === s.siteId))
  }

  public static FilterSoinTypesByRooms (roomForCurrentSite: RoomModel[], selectedRooms: number[], allSoinTypes: TextValue[]) {
    const allSupportedSoinIds = roomForCurrentSite.filter((r) => selectedRooms.includes(r.id!)).flatMap((r) => r.soinsTypeIds).filter(function (item, pos, self) {
      return self.indexOf(item) === pos
    })
    return allSoinTypes.filter((s) => allSupportedSoinIds.includes(s.value))
  }

  public static FilterSoinTypesByRoomsAndNurse (roomForCurrentSite: RoomModel[], selectedRooms: number[], allSoinTypes: TextValue[], nurse: AppUser) {
    const filteredByRooms = AgendaHelpers.FilterSoinTypesByRooms(roomForCurrentSite, selectedRooms, allSoinTypes)
    return filteredByRooms.filter((s) => nurse.soinsTypeIds.includes(s.value))
  }

  public static ExtractHours (hourPart: string|undefined|null, isStart: boolean) {
    const hourSplit = hourPart?.split(':')
    let hours = isStart ? 0 : 23
    let minutes = isStart ? 0 : 59
    if (hourSplit && hourSplit.length > 1) {
      hours = parseInt(hourSplit[0])
      minutes = parseInt(hourSplit[1])
    }
    return { hours, minutes }
  }

  private static ExtractDateFromBase (baseDate: Date, hourPart: string|undefined, isStart: boolean) {
    const time = this.ExtractHours(hourPart, isStart)
    return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), time.hours, time.minutes, 0, 0)
  }

  private static ExtractDate (datePart: string, hourPart: string|undefined, isStart: boolean) {
    const dateSplit = datePart.split('-')
    const time = this.ExtractHours(hourPart, isStart)
    return new Date(parseInt(dateSplit[0]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[2]), time.hours, time.minutes, 0, 0)
  }

  public static IsIntervalWithinDateRangeStr (intervalStart: string, intervalEnd: string, rangeStart: string, rangeEnd: string) {
    return AgendaHelpers.IsIntervalWithinDateRange(new Date(intervalStart), new Date(intervalEnd), new Date(rangeStart), new Date(rangeEnd))
  }

  public static IsIntervalWithinDateRange (intervalStart: Date, intervalEnd: Date, rangeStart: Date, rangeEnd: Date) {
    return intervalStart <= rangeEnd && intervalEnd >= rangeStart
  }

  private static IsAbsent (absenceStart: Date, absenceEnd: Date, meetingStart: Date, meetingEnd: Date, isStrict: boolean) {
    if (isStrict) {
      return absenceEnd > meetingStart && absenceStart < meetingEnd
    }
    return absenceEnd > meetingStart && absenceStart <= meetingEnd
  }

  public static IsRoomOpenAt (room: RoomModel, time: string, isStrict: boolean) {
    const openTime = AgendaHelpers.ExtractHours(room.openTime, true)
    const closeTime = AgendaHelpers.ExtractHours(room.closeTime, false)
    const timeToCheckTime = AgendaHelpers.ExtractHours(time, true)
    const roomOpenTime = new Date()
    roomOpenTime.setHours(openTime.hours)
    roomOpenTime.setMinutes(openTime.minutes)
    roomOpenTime.setSeconds(0)
    const roomCloseTime = new Date()
    roomCloseTime.setHours(closeTime.hours)
    roomCloseTime.setMinutes(closeTime.minutes)
    roomCloseTime.setSeconds(0)
    const timeToCheckDate = new Date()
    timeToCheckDate.setHours(timeToCheckTime.hours)
    timeToCheckDate.setMinutes(timeToCheckTime.minutes)
    timeToCheckDate.setSeconds(0)

    if (isStrict) {
      return timeToCheckDate >= roomOpenTime && timeToCheckDate < roomCloseTime
    }
    return timeToCheckDate >= roomOpenTime && timeToCheckDate <= roomCloseTime
  }

  private static GetEndDate (endDate: string|undefined) {
    if (endDate) {
      return endDate
    }
    return '9999-01-01'
  }

  public static GetEventColor (allColors: string[], soinTypeId: number, appointmentType: number, isFixed?: boolean) {
    if (appointmentType === 1) {
      // id of soin type is 1-based, but array indices are 0-based
      return allColors[soinTypeId - 1]
    }
    return isFixed === true ? '#3796FF' : allColors[allColors.length - 1]
  }

  public static IsNursePresentUnique (nurse: AppUser, allAbsences: AbsenceModel[], dateStart: Date, dateEnd: Date, isStrict: boolean) {
    const uniqueAbsences = allAbsences.filter((a) => a.period === 1 && a.userId === nurse.id)
    const isNurseAbsent = uniqueAbsences.filter((a) => {
      const absenceStart = AgendaHelpers.ExtractDate(a.dateRange?.from!, a.startHour, true)
      const absenceEnd = AgendaHelpers.ExtractDate(AgendaHelpers.GetEndDate(a.dateRange?.to), a.endHour, false)
      return AgendaHelpers.IsAbsent(absenceStart, absenceEnd, dateStart, dateEnd, isStrict)
    })
    const outcome = uniqueAbsences.length === 0 || isNurseAbsent.length === 0
    return outcome
  }

  public static IsNursePresentDaily (nurse: AppUser, allAbsences: AbsenceModel[], dateStart: Date, dateEnd: Date, isStrict: boolean) {
    const dailyAbsences = allAbsences.filter((a) => a.period === 2 && a.userId === nurse.id)
    const isNurseAbsent = dailyAbsences.filter((a) => {
      const absenceStartPeriod = AgendaHelpers.ExtractDate(a.dateRange?.from!, a.startHour, true)
      const absenceEndPeriod = AgendaHelpers.ExtractDate(AgendaHelpers.GetEndDate(a.dateRange?.to), a.endHour, false)
      const hasAbsenceStarted = AgendaHelpers.IsAbsent(absenceStartPeriod, absenceEndPeriod, dateStart, dateEnd, isStrict)
      if (hasAbsenceStarted) {
        const absenceStart = AgendaHelpers.ExtractDateFromBase(dateStart, a.startHour, true)
        const absenceEnd = AgendaHelpers.ExtractDateFromBase(dateStart, a.endHour, false)
        return AgendaHelpers.IsAbsent(absenceStart, absenceEnd, dateStart, dateEnd, isStrict)
      }
      return false
    })
    return dailyAbsences.length === 0 || isNurseAbsent.length === 0
  }

  public static IsNursePresentWeekly (nurse: AppUser, allAbsences: AbsenceModel[], dateStart: Date, dateEnd: Date, isStrict: boolean) {
    const weeklyAbsences = allAbsences.filter((a) => a.period === 3 && a.userId === nurse.id)
    const isNurseAbsent = weeklyAbsences.filter((a) => {
      const absenceStartPeriod = AgendaHelpers.ExtractDate(a.dateRange?.from!, a.startHour, true)
      const absenceEndPeriod = AgendaHelpers.ExtractDate(AgendaHelpers.GetEndDate(a.dateRange?.to), a.endHour, false)
      const appointmentDayOfTheWeek = mappingJSDate2ourWeekDays.find((d) => d.jsDay === dateStart.getDay())?.weekDayValue
      const absenceDaysOfTheWeek = a.daysRepeating
      const included = absenceDaysOfTheWeek?.includes(appointmentDayOfTheWeek!)
      const hasAbsenceStarted = AgendaHelpers.IsAbsent(absenceStartPeriod, absenceEndPeriod, dateStart, dateEnd, isStrict)
      if (included && hasAbsenceStarted) {
        const absenceStart = AgendaHelpers.ExtractDateFromBase(dateStart, a.startHour, true)
        const absenceEnd = AgendaHelpers.ExtractDateFromBase(dateStart, a.endHour, false)
        return AgendaHelpers.IsAbsent(absenceStart, absenceEnd, dateStart, dateEnd, isStrict)
      }
      return false
    })
    return weeklyAbsences.length === 0 || isNurseAbsent.length === 0
  }

  public static IsNurseAbsent (nurse: AppUser, allAbsences: AbsenceModel[], dateStart: Date, dateEnd: Date, isStrict: boolean) {
    return !AgendaHelpers.IsNursePresentUnique(nurse, allAbsences, dateStart, dateEnd, isStrict) ||
           !AgendaHelpers.IsNursePresentDaily(nurse, allAbsences, dateStart, dateEnd, isStrict) ||
           !AgendaHelpers.IsNursePresentWeekly(nurse, allAbsences, dateStart, dateEnd, isStrict)
  }

  public static FilterNursesBySoinAndAbsence (allNurses: AppUser[], soinTypeId: number, allAbsences: AbsenceModel[],
    dateStart: Date, dateEnd: Date, appointmentTypeId: number, nurseId?: string) {
    let filteredNurses = allNurses.filter((n) => n.isEnabled || n.id === nurseId)
    if (appointmentTypeId === 1) {
      filteredNurses = this.FilterNursesBySoin(allNurses, soinTypeId, nurseId)
    }

    // filter unique
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentUnique(n, allAbsences, dateStart, dateEnd, true)
    })
    // filter daily
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentDaily(n, allAbsences, dateStart, dateEnd, true)
    })

    // filter weekly absences
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentWeekly(n, allAbsences, dateStart, dateEnd, true)
    })
    return filteredNurses
  }

  public static FilterNursesByAbsence (allNurses: AppUser[], allAbsences: AbsenceModel[], dateStart: Date, dateEnd: Date, currentNurseId) {
    let filteredNurses = allNurses.filter((n) => n.isEnabled || n.id.toLowerCase() === currentNurseId)

    // filter unique
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentUnique(n, allAbsences, dateStart, dateEnd, false) || n.id.toLowerCase() === currentNurseId
    })

    // filter daily
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentDaily(n, allAbsences, dateStart, dateEnd, false) || n.id.toLowerCase() === currentNurseId
    })

    // filter weekly absences
    filteredNurses = filteredNurses.filter((n) => {
      return AgendaHelpers.IsNursePresentWeekly(n, allAbsences, dateStart, dateEnd, false) || n.id.toLowerCase() === currentNurseId
    })

    return filteredNurses
  }

  public static FilterNursesBySoin (allNurses: AppUser[], soinTypeId: number, nurseId?: string) {
    return allNurses.filter((n) => n.soinsTypeIds.includes(soinTypeId) || n.id === nurseId)
  }

  public static FilterPrestations (allPrestations: PrestationAgendaResponseModel[], soinTypeId: number) {
    const prestationCategoryId = mappingTypeDeSoin2PrestationCategory.find((p) => p.soinTypeId === soinTypeId)?.prestationCategoryId
    return allPrestations.filter((p) => p.prestationTypeId === prestationCategoryId)
  }

  public static IsAgendaLieu (agendaType: number) {
    return agendaType === 0
  }

  public static IsAgendaUser (agendaType: number) {
    return agendaType === 1
  }

  public static FindPlanning (relevantPlannings: RoomPlanningPeriodModel[], date: string) {
    return relevantPlannings.find(p => AgendaHelpers.IsIntervalWithinDateRangeStr(date, date, p.dateRange?.from!, p.dateRange?.to!))
  }

  public static FindRoomPlanning (planning: RoomPlanningPeriodModel, roomsForSelectedSite: RoomModel[], roomName: string) {
    return planning.roomPlannings?.find(r => roomsForSelectedSite.some(s => s.id === r.roomId) && r.room === roomName)
  }

  public static FindRoomPlanningByRoomId (planning: RoomPlanningPeriodModel, roomId: number) {
    return planning.roomPlannings?.find(r => r.roomId === roomId)
  }
}
