


















































import { ValidationProvider } from 'vee-validate'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ValidationProvider
  }
})
export default class TextInput extends Vue {
  @Prop({ default: '' }) public value!: string
  @Prop({ default: '' }) public rules!: string
  @Prop({ default: '' }) public name!: string
  @Prop({ default: '' }) public label!: string
  @Prop({ default: undefined }) public vid!: string | undefined
  @Prop({ default: 'text' }) public type!: string
  @Prop({ default: '' }) public appendicon!: string
  @Prop({ default: false }) public readonly!: boolean
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: null }) public clearable!: boolean
  @Prop({ default: false }) public hideDetails!: boolean | string
  @Prop({ default: false }) public counter!: boolean | number
  @Prop() public id!: string
  @Prop({ default: 1 }) public step!: number
  @Prop() public min!: unknown
  @Prop() public max!: unknown
  @Prop() public customOn!: any
  @Prop() public customBind!: any
  public focus = false
  public hasError = false
  public clearableProp = false

  public onBlur (e) {
    this.focus = false
    this.$emit('blur', e)
  }

  public onFocus (e) {
    this.focus = true
    this.$emit('focus', e)
  }

  public onChangeEvent (e) {
    this.$emit('change', e)
  }

  @Watch('innerValue') onChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value') onValueChanges (val: string) {
    if (val !== this.innerValue) {
      this.innerValue = val
    }
  }

  public onPaste (e) {
    const pastedData = e.clipboardData.getData('text')
    if (this.counter && this.value.concat(pastedData).length >= this.counter) {
      e.preventDefault()
    }
  }

  public checkCounter (e) {
    if (this.counter &&
        e.keyCode !== 46 && // keycode for delete
        e.keyCode !== 8 && // keycode for backspace
        this.value.length >= this.counter) {
      e.preventDefault()
    }
  }

  public innerValue = ''

  public get hasValue () {
    return !!this.innerValue
  }

  public get requiredClass () {
    return this.rules?.includes('required') ? 'required' : ''
  }

  public emitAppendClick () {
    this.$emit('appendiconClick')
  }

  public mounted () {
    if (this.type === 'number' || !!this.value) {
      this.innerValue = this.value
    }

    if (this.clearable === null && !this.readonly && !this.disabled && this.requiredClass !== 'required' && (this.type === 'date' || this.type === 'datetime' || this.type === 'time')) {
      this.clearableProp = true
    } else {
      this.clearableProp = this.clearable
    }
  }
}
