import { LayoutService } from '@/services/layout-service'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DossierAssurance, DossierNavItems, DossierNavItemsNoCreate, DossierTabs, reseaux } from '../Static'
import InfoPage from '@/views/Patients/Dossier/InfoPage/InfoPage.vue'
import InfoAdmin from '@/views/Patients/Dossier/InfoAdmin/InfoAdmin.vue'
import InfoAssurance from '@/views/Patients/Dossier/InfoAssurance/InfoAssurance.vue'
import InfoSocial from '@/views/Patients/Dossier/InfoSocial/InfoSocial.vue'
import { PatientService } from '@/services/patient-service'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { Subscription } from 'rxjs'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { AxiosResponse } from 'axios'
import Commons from '@/components/shared/Helpers/commons'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'
import { accessDeniedError } from '@/shared/constants/Constants'

@Component({
  components: {
    InfoPage,
    InfoAdmin,
    InfoAssurance,
    InfoSocial,
    Alert
  }
})
export default class Dossier extends Vue {
  @Prop() mode!: string
  private patientService = PatientService.getInstance()
  private subscription!: Subscription
  public selectedDossier: dossierPatientModel | any = null

  public tab = null
  public tabs = ['Données personnelles']

  public alertMessages: string[] = []
  public professionalsList: any[] = []
  public showAlert = false
  public alertType: 'success' | 'error' = 'success'
  public isLoading = false
  public searchLoading = false

  private layoutService = LayoutService.getInstance()

  private accessDenied = false

  public dossierAssurance = {} as DossierAssurance

  public mounted () {
    this.accessDenied = false
    if (this.mode === 'new') {
      if (!ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
        this.accessDenied = true
        this.updateAlertMessage(accessDeniedError)
      }
      this.resetSelectedDossier()
    }
    if (ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
      this.layoutService.updateDrawerList(DossierNavItems)
    } else {
      this.layoutService.updateDrawerList(DossierNavItemsNoCreate)
    }
    this.subscription = this.patientService.dossierSelected$.subscribe(dossier => {
      this.selectedDossier = dossier
      this.getDossierAssurance()
      this.getDossierReseau()
    })
    sessionStorage.setItem('selectedDossier', JSON.stringify(this.selectedDossier))
  }

  @Watch('selectedDossier') changes (dossier: dossierPatientModel) {
    if (dossier?.guid) {
      Commons.updateDossierName()
    }
  }

  @Watch('mode')
  public modeChanges () {
    if (this.mode === 'new') {
      this.resetSelectedDossier()
      this.selectedDossier = {}
      Commons.restNavDrawer()
    }
  }

  get isUpdateMode () {
    const hasId = !!this.selectedDossier?.guid
    if (hasId) {
      this.tabs = DossierTabs
    } else {
      this.tabs = ['Données personnelles']
    }
    return hasId
  }

  public resetSelectedDossier () {
    this.patientService.updateDossierSelected({})
  }

  public async submitInfoPage (reqpayload: any) {
    this.showAlert = false
    const response: any = await this.patientService.createPatientInfo(reqpayload)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      })

    if (response?.status === 200) {
      this.updateAlertSucessMessage()
      this.selectedDossier = response.data
      this.patientService.updateDossierSelected(this.selectedDossier)
    }
  }

  private updateAlertSucessMessage () {
    this.alertType = 'success'
    this.alertMessages = ['L\'enregistrement des données s\'est déroulé avec succès']
    this.showAlert = true
  }

  private updateAlertMessage (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  public async saveReseauInfo (reseauInfo: any) {
    this.isLoading = true
    this.showAlert = false
    this.hideAlert()
    if (!reseauInfo?.reseauMedicalExtra?.type) {
      reseauInfo.reseauMedicalExtra = null
    }
    const response = await this.patientService.upsetReseauInfo((this.selectedDossier as dossierPatientModel).guid, reseauInfo)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      })
      .finally(() => {
        this.isLoading = false
      })
    if ((response as AxiosResponse<any>).status === 200) {
      this.updateAlertSucessMessage()
      this.getDossierReseau()
    }
  }

  public async getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      await this.patientService.getDossierAssuranceInfo(this.selectedDossier.guid).then(data => {
        this.mapDossierAssuranceResponse(data ?? {})
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      })
    }
  }

  public async getDossierReseau () {
    if (this.selectedDossier?.guid) {
      this.isLoading = true
      const response: any = await this.patientService.getDossierReseauInfo(this.selectedDossier.guid)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.updateAlertMessage(res)
        })
        .finally(() => {
          this.isLoading = false
        })
      if (response?.status === 200) {
        this.mapDossierReseau(response.data)
      }
    }
  }

  public async getProfessionalsListByType (searchInput: { type: number; searchTerm: string }) {
    if (!searchInput.type || searchInput.type === 6) {
      this.professionalsList = []
      return
    }
    this.searchLoading = true
    const response = await this.patientService.getProfessionalsByType(searchInput.type)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      })
      .finally(() => {
        this.searchLoading = false
      })
    this.professionalsList = response as any[]
  }

  public async deleteDossierReseauItem (reseauItemId: string) {
    if (reseauItemId) {
      this.isLoading = true
      const response: any = await this.patientService.deleteDossierReseauInfo(reseauItemId)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.updateAlertMessage(res)
        })
        .finally(() => {
          this.isLoading = false
        })
      if (response?.status === 200) {
        this.getDossierReseau()
        this.updateAlertSucessMessage()
      }
    }
  }

  public async deleteDossierReseauLinkedProfessional (dossierId: string, linkedId: number) {
    if (dossierId) {
      this.isLoading = true
      const response: any = await this.patientService.deleteDossierReseauInfo(dossierId, linkedId)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.updateAlertMessage(res)
        })
        .finally(() => {
          this.isLoading = false
        })
      if (response?.status === 200) {
        this.getDossierReseau()
        this.updateAlertSucessMessage()
      }
    }
  }

  private mapDossierAssuranceResponse (assuranceResponse: DossierAssurance) {
    this.dossierAssurance = assuranceResponse
  }

  private mapDossierReseau (dossierResponse: any) {
    const proData: any[] = []
    const famData: any[] = []
    dossierResponse.forEach((reseau: any) => {
      if (reseau.reseauMedicalExtra?.type) {
        proData.push(reseau)
      } else {
        famData.push(reseau)
      }
    })
    reseaux[0].data = famData
    reseaux[1].data = proData
  }

  public goHome () {
    this.$router.push({ name: 'patient' })
  }

  public hideAlert () {
    this.showAlert = false
    this.alertMessages = []
  }

  public destroyed () {
    this.subscription.unsubscribe()
    this.layoutService.updateDrawerList([])
  }

  public get dossierId () {
    return this.selectedDossier?.guid
  }
}
