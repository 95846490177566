import axios from 'axios'

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  maxBodyLength: 5.5e+7,
  maxContentLength: 5e+7,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
})

export const fHttp = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'multipart/form-data'
  }
})
