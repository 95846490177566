export const civilites = [
  'Madame',
  'Mademoiselle',
  'Monsieur'
]

export const sexes = [
  { text: 'Féminin', value: 1 },
  { text: 'Masculin', value: 2 },
  { text: 'Autre', value: 3 }
]

export const invoiceSexes = [
  { text: 'F', value: 1 },
  { text: 'M', value: 2 },
  { text: 'Autre', value: 3 }
]

export const invoiceFormulePolitesse = [
  { text: 'Madame', value: 1 },
  { text: 'Monsieur', value: 2 },
  { text: 'Madame, Monsieur', value: 3 }
]

export const etatCivil = [
  { text: 'Célibataire', value: 1 },
  { text: 'En couple', value: 2 },
  { text: 'Marié(e)', value: 3 },
  { text: 'Pacsé(e)', value: 4 },
  { text: 'Séparé(e)', value: 5 },
  { text: 'Divorcé(e)', value: 6 },
  { text: 'Veuf/Veuve', value: 7 }
]

export const districts = [
  'Broye',
  'Fribourg ville',
  'Glâne',
  'Gruyère',
  'Lac',
  'Sarine',
  'Singine',
  'Veveyse',
  'Hors-canton'
]

export const langues = [
  { name: 'French', text: 'Français', value: 1 },
  { name: 'German', text: 'Allemand', value: 2 },
  { name: 'Italien', text: 'Italien', value: 3 },
  { name: 'English', text: 'Anglais', value: 4 }
]

export const statusDossier = [
  { text: 'En cours', value: 1 },
  { text: 'Décédé', value: 2 },
  { text: 'Annulé', value: 3 },
  { text: 'Supprimé', value: 4 }
]

export const statusMembre = [
  { text: 'Membre honoraire/soutien', value: 1 },
  { text: 'Membre collectif', value: 2 },
  { text: 'Membre individuel', value: 3 },
  { text: 'Membre décédé', value: 4 },
  { text: 'Membre comité', value: 5 },
  { text: 'Démission', value: 6 },
  { text: 'Non membre', value: 7 }
]

export const statusOFAS = [
  { text: 'AI+  avec droits acquis', value: 1 },
  { text: 'AI- sans droits acquis', value: 2 },
  { text: 'AVS+ avec droits acquis', value: 3 },
  { text: 'AVS- sans droits acquis', value: 4 }
]

export const representantTypes = [
  'Parents',
  'Tutelle',
  'Curatelle',
  'Correspondant',
  'Foyer'
]

export const professionnelTypes = [
  { text: 'Médecin spécialiste', value: 1 },
  { text: 'Médecin généraliste', value: 2 }
]

export const theme = [
  { text: 'Problem', value: 1 },
  { text: 'Objective', value: 2 },
  { text: 'Action', value: 3 }
]

export const consultationGenre = [
  { text: 'Évaluation initiale ', value: 1 },
  { text: 'Réévaluation', value: 2 }
]

export const consultationGenrePediatrie = [
  { text: 'Évaluation initiale ', value: 1 },
  { text: 'Réévaluation', value: 2 },
  { text: 'Intervention en classe', value: 3 },
  { text: 'Consultation camp parents/enfant', value: 4 }
]

export const prescriptionTypes = [
  { text: 'Prescription initiale ', value: 1 },
  { text: 'Renouvellement', value: 2 }
]

export const diabeteType = [
  { text: 'Pré-diabète', value: 1 },
  { text: 'Secondaire', value: 2 },
  { text: 'Type 1', value: 3 },
  { text: 'Type 2', value: 4 },
  { text: 'Gestationnel', value: 5 },
  { text: 'Autre', value: 6 }
]

export const consultationTypes = [
  { text: 'Diabétique', value: 1 },
  { text: 'Diététique', value: 2 },
  { text: 'Pré-diabète', value: 3 },
  { text: 'Pédiatrie', value: 4 },
  { text: 'Podo', value: 5 }
]

export const periode = [
  { text: 'Petit-déjeuner', value: 1 },
  { text: 'Collation matin', value: 2 },
  { text: 'Midi', value: 3 },
  { text: 'Collation après-midi', value: 4 },
  { text: 'Souper', value: 5 },
  { text: 'Collation soirée', value: 6 },
  { text: 'Collation nuit', value: 7 }
]

export const periodicityTypes = [
  { text: 'Unique', value: 1 },
  { text: 'Quotidienne', value: 2 },
  { text: 'Hebdomadaire', value: 3 }
]

export const mappingJSDate2ourWeekDays = [
  { weekDayValue: 1, jsDay: 1, description: 'lundi' },
  { weekDayValue: 2, jsDay: 2, description: 'mardi' },
  { weekDayValue: 3, jsDay: 3, description: 'mercredi' },
  { weekDayValue: 4, jsDay: 4, description: 'jeudi' },
  { weekDayValue: 5, jsDay: 5, description: 'vendredi' },
  { weekDayValue: 6, jsDay: 6, description: 'samedi' },
  { weekDayValue: 7, jsDay: 0, description: 'dimanche' }
]

export const weekDays = [
  { text: 'Lundi', value: 1 },
  { text: 'Mardi', value: 2 },
  { text: 'Mercredi', value: 3 },
  { text: 'Jeudi', value: 4 },
  { text: 'Vendredi', value: 5 },
  { text: 'Samedi', value: 6 },
  { text: 'Dimanche', value: 7 }
]

export const rdvType = [
  { text: 'Consultation', value: 1 },
  { text: 'Autre', value: 2 }
]

export const pointsDiscutesTypes = [
  { text: 'Physio pathologie du diabète', value: 1 },
  { text: 'Valeurs de référence pour glycémie de type 1', value: 2 },
  { text: 'Qu\'est-ce qu\'une glycémie, comment faire ?', value: 3 },
  { text: 'Différents ttt : stylos ou pompes', value: 4 },
  { text: 'Hypoglycémies : description, causes', value: 5 },
  { text: 'Signes, diminution de l\'attention lors d\'une interro', value: 6 },
  { text: 'Combien de sucres de raisins ou de jus de fruits pur, quand donner sucres lents ?', value: 7 },
  { text: 'Glucagon avec technique d\'administration, conservation, transport, péremption', value: 8 },
  { text: 'Déconnecter pompe de l\'enfant si malaise', value: 9 },
  { text: 'Rôle des copains savoir avertir un adult', value: 10 },
  { text: 'Alimentation : tout mais à quantité variable', value: 11 },
  { text: 'Ne pas partager sa collation', value: 12 },
  { text: 'Collation avec nombre HC : example disque grenouille', value: 13 },
  { text: 'Anniversaire en classe : compte dans sa collation mais aussi voir avec les parents', value: 14 },
  { text: 'Sport sans interdit : peut tout faire', value: 15 },
  { text: 'Selon horaire collation plus grande ou repas précédent plus important en HC', value: 16 },
  { text: 'Contrôle glycémique toujours après le sport et collation après', value: 17 },
  { text: 'Hyperglycémie : description, causes', value: 18 },
  { text: 'Donner carte visite et prospectus', value: 19 },
  { text: 'Information diabète pour la classe ?', value: 20 }
]

export const situationEvalueesTypes = [
  { text: 'Schéma ttt actualisé', value: 1 },
  { text: 'Glycémies doigts', value: 2 },
  { text: 'Hypo : signes Nbre sucres', value: 3 },
  { text: 'Acétone quand comment', value: 4 },
  { text: 'Injections Situes Peau Autonomie', value: 5 },
  { text: 'Pompe Bolus 10h Problèmes', value: 6 },
  { text: 'Matériel à jour', value: 7 },
  { text: 'Sport compensation', value: 8 },
  { text: 'Ecoles problèmes', value: 9 },
  { text: 'Camps', value: 10 },
  { text: 'Collations 10/16/21h', value: 11 },
  { text: 'Rdv Diet', value: 12 },
  { text: 'Camps diabète', value: 13 },
  { text: 'Infos AFD Activité matériel', value: 14 }
]

export const documentDiversTypes = [
  { text: 'Carnet de glycémie', value: 1 },
  { text: 'Carte de médicaments', value: 2 },
  { text: 'Données administratives', value: 3 },
  { text: 'Données Labo', value: 4 },
  { text: 'PAD', value: 5 },
  { text: 'OPAS (renouvellement)', value: 6 },
  { text: 'Ordonnance', value: 7 },
  { text: 'Rapport médicaux', value: 8 }
]

export const prescriptionStatusTypes = [
  { text: 'À compléter', value: 1 },
  { text: 'Complète', value: 2 },
  { text: 'Envoyée', value: 3 }
]

export const PrestationDietetique = 1
export const PrestationDiabetique = 2

export const prestationCategories = [
  { text: 'Consultation diabétique', value: PrestationDiabetique },
  { text: 'Consultation diététique', value: PrestationDietetique },
  { text: 'Consultation pédiatrie', value: 5 },
  { text: 'Consultation podo', value: 3 },
  { text: 'Consultation pré-diabète', value: 4 },
  { text: 'Cours en groupe', value: 7 },
  { text: 'Matériel', value: 6 },
  { text: 'OPAS podo', value: 8 },
  { text: 'OPAS pré-diabète', value: 9 },
  { text: 'OPAS pédiatrie', value: 10 }
]

export const renewablePrestationCategoriesAll = 0

export const renewablePrestationCategories = [
  { text: 'Tous', value: renewablePrestationCategoriesAll },
  { text: 'Matériel', value: 6 },
  { text: 'OPAS pédiatrie', value: 10 },
  { text: 'OPAS podo', value: 8 },
  { text: 'OPAS pré-diabète', value: 9 }
]

export const mappingTypeDeSoin2PrestationCategory = [
  { prestationCategoryId: PrestationDietetique, soinTypeId: 2, description: 'Diététique' },
  { prestationCategoryId: PrestationDiabetique, soinTypeId: 1, description: 'Diabétique' },
  { prestationCategoryId: 3, soinTypeId: 5, description: 'Podo' },
  { prestationCategoryId: 4, soinTypeId: 3, description: 'Pré-diabète' },
  { prestationCategoryId: 5, soinTypeId: 4, description: 'Pédiatrie' }
]

export const abseceTypes = [
  { text: 'Vacances', value: 1 },
  { text: 'Formation', value: 2 },
  { text: 'Maladie', value: 3 },
  { text: 'Récupération HS', value: 4 },
  { text: 'Congé hebdomadaire', value: 5 },
  { text: 'Pause', value: 6 }
]

export const adviceType = [
  { text: 'Problème', value: 1 },
  { text: 'Evaluation', value: 2 }
]

export function getPrescriptionStatusText (id: number) {
  return prescriptionStatusTypes.find(s => s.value === id)?.text
}

export function getPrestationCategoryName (id: number) {
  return prestationCategories.find(x => x.value === id)?.text
}

export const MaterielNonPediatriqueLibre = 1
export const MaterielNonPediatriquePrescription = 2
export const MaterielPediatriqueLibre = 3
export const MaterielPediatriquePrescription = 4

export const materielType = [
  { text: 'Matériel non pédiatrique libre', value: MaterielNonPediatriqueLibre },
  { text: 'Matériel non pédiatrique sur prescription', value: MaterielNonPediatriquePrescription },
  { text: 'Matériel pédiatrique libre', value: MaterielPediatriqueLibre },
  { text: 'Matériel pédiatrique sur prescription', value: MaterielPediatriquePrescription }
]

export enum MaterielCategoryEnum {
  MaterielCategoryLibre = 1,
  MaterielCategoryPrescription
}
