
import { DrawerItem } from '@/components/shared/Models/drawer-model'
import { BehaviorSubject } from 'rxjs'

export class LayoutService {
  private static instance: LayoutService

  // drawer visible state
  private internalState = true

  // Observable drawer state source
  private _drawerState = new BehaviorSubject<boolean>(true)
  // Observable drawer stream
  drawerState$ = this._drawerState.asObservable()

  private _drawerItems = new BehaviorSubject<DrawerItem[]>([])
  drawerItems$ = this._drawerItems.asObservable()

  /**
   * getInstance
   */
  public static getInstance (): LayoutService {
    if (!LayoutService.instance) {
      LayoutService.instance = new LayoutService()
    }

    return LayoutService.instance
  }

  public updateState (val?: boolean) {
    this.internalState = val ?? !this.internalState
    this._drawerState.next(this.internalState)
  }

  public updateDrawerList (items: DrawerItem[]) {
    this._drawerItems.next(items)
  }
}
