
import { DossierPatientImportantAppointmentViewModel } from '@/models/dossier-response-model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getSoinName } from '@/views/Administration/Constants'

@Component({
  components: {
  }
})

export default class ImportantAppointmentInfo extends Vue {
  @Prop() warning!: DossierPatientImportantAppointmentViewModel

  public soinName (consultationTypeId) {
    return getSoinName(consultationTypeId)
  }
}
