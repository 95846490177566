import { AuthService } from './auth-service'
import http from '@/http-client'
import Commons from '@/components/shared/Helpers/commons'

export class ErrorService {
  public static async handleError (error: any): Promise<{ errors: any[]; title: string }> {
    const modelStateErrors: string[] = []
    const authService = AuthService.getInstance()
    console.log(error)
    if (!Commons.IsProduction()) {
      console.log(JSON.stringify(error))
    }
    if (!error.status) {
      if (error.error === 'login_required') {
        await authService.login()
        return { errors: ['Il semble que votre session soit expirée'], title: 'Erreur de connexion au serveur' }
      }
      // This normally occurs when the session timed out due to inactivity. In that case we want the user to login again.
      // This can also occur if the backend went down, in that case logging in again will not work, so donàt do it otherwise
      // we will try to login forever in an infinite loop
      const pinged = await ErrorService.pingBackend()
      if (pinged) {
        await authService.login()
        return { errors: ['Il semble que votre session ait expiré'], title: 'Erreur de connexion au serveur' }
      }
      return { errors: ['Impossible de se connecter au serveur.'], title: 'Erreur de connexion au réseau' }
    }
    if (error.status) {
      switch (error.status) {
        case 500:
          return { errors: ['Une erreur s\'est produite lors de l\'exécution de cette action'], title: 'Erreur de serveur' }
        case 204:
          break
        case 404:
        case 405:
          return { errors: ['La page ou la ressource demandée n\'a pas été trouvée '], title: 'Non trouvé' }
        case 403:
          return { errors: ['Vous ne disposez pas de droits suffisants pour effectuer cette opération'], title: 'Interdit' }
        case 413:
          return { errors: ['Impossible de traiter la requête. La taille maximale des fichier a été dépassée'], title: 'Error fichier' }
        case 400:
          ErrorService.processError(error, modelStateErrors)
          return { errors: modelStateErrors, title: error.data.title as string }
        case 401:
          await authService.login()
          return { errors: ['Authentification expirée, connexion requise'], title: 'Session expirée' }
        default:
          return { errors: [error.data], title: 'Erreur de connexion au réseau' }
      }
    } else {
      return { errors: ['Une erreur inattendue s\'est produite pour empêcher l\'exécution de cette action'], title: 'Erreur inconnue' }
    }
    return { errors: modelStateErrors, title: error.data.title as string }
  }

  private static async pingBackend () {
    let retValue
    await http.get('/home/ping').then((v) => {
      retValue = v.status === 200
    }).catch(() => {
      retValue = false
    })
    return retValue
  }

  private static processError (error: any, modelStateErrors: string[]) {
    if (error.data.errors) {
      ErrorService.extractErrors(error.data.errors, modelStateErrors)
    } else {
      if (error.data.StatusCode === 500) {
        // for 500 we may get a custom format in our error object
        modelStateErrors.push(error.data.Message as string)
      } else {
        const errors: any[] = error.data.InvalidValue ? error.data.InvalidValue : error.data
        if (errors.constructor === Array) {
          errors.forEach((item: any) => {
            if (item.description) {
              modelStateErrors.push(item.description as string)
            } else {
              modelStateErrors.push(item as string)
            }
          })
        }
      }
    }
  }

  private static extractErrors (errors: any, modelStateErrors: string[]) {
    for (const key in errors) {
      if (errors[key]) {
        const errorGrp: string[] = errors[key]
        if (errorGrp) {
          errorGrp.forEach(err => {
            modelStateErrors.push(err)
          })
        }
      }
    }
  }
}
