import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { PatientInfoService } from '@/services/patient-info-service'
import { ErrorService } from '@/services/error.service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    ValidationObserver,
    Alert,
    Confirm
  }
})
export default class InfosImportant extends Vue {
  @Prop({ default: false })
  public show!: boolean

  private infoService = PatientInfoService.getInstance()
  public infosImportant: InfosImportantModel[] = []
  public infoImportantModel: InfosImportantModel = { information: '', type: '' }
  public dossier: dossierPatientModel | null = null
  public showDialogForm = false
  public showMainDialog = true
  public isSaving = false
  public isEdit = false
  public importantTypes = ['Administration', 'Soins']
  public errorMessages: string[] = []
  public showConfirm = false
  public initLoading = false

  @Watch('show')
  public async onChanged (val: boolean) {
    if (val) {
      const cachedDossier = sessionStorage.getItem('selectedDossier')
      if (cachedDossier) {
        this.dossier = JSON.parse(cachedDossier) as dossierPatientModel
        if (!this.dossier?.guid) {
          this.$router.push('/patient')
          return
        }
        await this.getEspacesInfoItems()
      }
      this.showMainDialog = val
    }
  }

  public async mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.dossier = JSON.parse(cachedDossier) as dossierPatientModel
      await this.getEspacesInfoItems()
    }
  }

  private async getEspacesInfoItems () {
    this.initLoading = true
    if (this.dossier?.guid) {
      this.infosImportant = await this.infoService.getEspaceInfoItem(this.dossier?.guid as string)
        .finally(() => {
          this.initLoading = false
        });
      (this.dossier as dossierPatientModel).espaceItems = this.infosImportant
      sessionStorage.setItem('selectedDossier', JSON.stringify(this.dossier))
    } else {
      this.$router.push('/patient')
    }
  }

  get hasItems () {
    return this.infosImportant?.length > 0
  }

  private resetForm () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.infoImportantModel = { information: '', type: '' }
    this.isEdit = false
  }

  public editInfoItem (item: InfosImportantModel) {
    this.infoImportantModel = item
    this.isEdit = true
    this.showDialogForm = true
    this.showMainDialog = false
  }

  public confirmInfotemDelete (infoitem: InfosImportantModel) {
    this.showConfirm = true
    this.infoImportantModel = infoitem
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.initLoading = true
      const results = await this.infoService.deleteEspaceInfoItem(this.infoImportantModel.id as string)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.initLoading = false
          this.resetForm()
        })
      if (results) {
        this.errorMessages = []
        this.getEspacesInfoItems()
      }
    }
  }

  public hideDialogForm () {
    this.resetForm()
    this.showDialogForm = false
    this.showMainDialog = true
  }

  public showAddDialogForm () {
    this.showDialogForm = true
    this.showMainDialog = false
  }

  public closeDialog () {
    this.hideDialogForm()
    this.showMainDialog = false
    this.infoImportantModel = { information: '', type: '' }
    this.$emit('close')
  }

  public getIconType (type: string) {
    return (type === 'Administration' ? 'mdi-clipboard-alert-outline'
      : type === 'Soins' ? 'mdi-account-alert' : 'mdi-clock-alert')
  }

  public async saveEspaceInfoItem () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (!this.dossier?.guid) this.errorMessages.push('Error with dossier Id')
    if (isValid) {
      this.isSaving = true
      const response = await this.infoService.addEspaceInfoItem(this.dossier?.guid as string, this.infoImportantModel)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })

      if (response) {
        await this.getEspacesInfoItems()
        this.hideDialogForm()
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }
}

export interface InfosImportantModel {
  id?: string;
  dossierId?: string;
  type: string;
  information: string;
}
