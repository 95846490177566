import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import TableCellInput from '@/components/shared/TableCellInput.vue'
import { EvaluationInitialeModel, EvaluationReportModel } from '@/models/consultation-model'
import { EvaluationInitialeHelper, EvaluationTblStructureModel } from './EvaluationInitialHelper'
import { PatientService } from '@/services/patient-service'

@Component({
  components: {
    TableCellInput
  }
})
export default class EvaluationInitiale extends Vue {
  @PropSync('data')
  public syncData!: EvaluationInitialeModel[]

  @PropSync('initEvalDuration')
  public syncedinitEvalDuration!: number | string

  @Prop()
  public type!: number

  @Prop()
  public createdByUserId!: string

  @Prop()
  public appointmentId!: number

  @Prop()
  public dossierId!: string

  private patientService = PatientService.getInstance()
  public evaluationInit: EvaluationTblStructureModel[] = []

  public mounted () {
    this.evaluationInit = EvaluationInitialeHelper.getTblStructure()
    this.mapDateEvaluationInitTblStruture(this.syncData)
  }

  private mapDateEvaluationInitTblStruture (data: EvaluationInitialeModel[]) {
    if (data) {
      data.map(initRow => {
        const tableRowInfo = this.evaluationInit.find(x => x.id === initRow.evaluationTypeId)
        if (initRow.frequencyTypeId && initRow.value) {
          if (tableRowInfo?.values) tableRowInfo.values[initRow.frequencyTypeId - 1].value = initRow.value.toString()
        }
      })
    }
  }

  public updatesyncData (rowId: number, colId: number, value: number) {
    const dataItem = this.syncData.find(x => x.evaluationTypeId === rowId && x.frequencyTypeId === colId)
    if (dataItem) {
      dataItem.value = value
    } else {
      const rowItem: EvaluationInitialeModel = { evaluationTypeId: rowId, frequencyTypeId: colId, value: value }
      this.syncData.push(rowItem)
    }
  }

  public generateReport () {
    const reportModel: EvaluationReportModel = {
      evaluations: this.syncData,
      consultationType: this.type,
      infirmiereId: this.createdByUserId,
      appointmentId: this.appointmentId,
      prescriptionTypeId: 1,
      dossierId: this.dossierId
    }
    this.patientService.updateEvaluationReportModelSelected(reportModel)
    this.$router.push({ name: 'EvaluationForm' })
  }
}
