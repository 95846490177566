var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[(!!_vm.errorMessages.length)?_c('div',[_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('div',{staticClass:"subsection"},[_c('h4',{staticClass:"subtitle"},[_vm._v("Historique des assurances")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){return _vm.openInsuranceDialog()}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle assurance à l'historique")])],1),_c('c-data-table',{attrs:{"headers":_vm.historyHeaders,"items":_vm.assuranceHistory,"hide-default-footer":_vm.assuranceHistory.length <= 10,"mobile-breakpoint":600,"loading":_vm.isHistoryLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isHistoryLoading }},scopedSlots:_vm._u([{key:"item.insuredFrom",fn:function(ref){
var item = ref.item;
return [_c('em',[_vm._v(_vm._s(_vm._f("date-fr")(item.dateRange.from)))])]}},{key:"item.insuredTo",fn:function(ref){
var item = ref.item;
return [(!!item.dateRange.to)?_c('em',[_vm._v(_vm._s(_vm._f("date-fr")(item.dateRange.to))+" "),(_vm.isCurrentInsurance(item))?_c('span',[_vm._v(" ("),_c('strong',[_vm._v("Assurance actuelle")]),_vm._v(")")]):_vm._e()]):(_vm.isCurrentInsurance(item))?_c('strong',[_vm._v("Assurance actuelle")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){$event.stopPropagation();return _vm.displayConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)})],1)],1)],1),_c('confirm',{attrs:{"visible":_vm.showDeleteConfirm,"title":("Supprimer " + (_vm.editedItem.assuranceAssuranceName)),"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer cette assurance de l'historique du patient ?"},on:{"close":function($event){return _vm.confirmCallback($event)}}}),_c('insurance-history-dialog',{attrs:{"visible":_vm.editDialog,"insuranceItem":_vm.editedItem,"errorMessages":_vm.errorMessages,"isLoading":_vm.isDialogBusy,"allAssurances":_vm.assurances},on:{"close":function($event){return _vm.closeInsuranceHistoryDialog()},"saveClick":function($event){return _vm.saveInsuranceHistory()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }