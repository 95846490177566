














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class DocAddressDr extends Vue {
  @Prop() public doctor!: string[]
}
