import { RouteConfig } from 'vue-router'
import Recherche from '@/views/Patients/Recherche/Recherche.vue'
import Index from '@/views/Index.vue'
import Dossier from '@/views/Patients/Dossier/Dossier.vue'
import Consultation from '@/views/Patients/Overview/Consultation/Consultation.vue'
import AuthCallback from '@/components/AuthCallbackComponent/auth-callback.vue'

export const routes: RouteConfig[] = [
  {
    path: '/auth-callback',
    name: 'Auth',
    component: AuthCallback,
    meta: {
      public: true
    }
  },
  {
    path: '/',
    component: Index,
    children: [
      {
        name: 'Home',
        path: '',
        component: Recherche
      },
      {
        path: '/profil',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profil" */ '../views/Profile/Profile.vue')
      }
    ]
  },

  /* --- Patients --- */
  {
    path: '/patient',
    component: Index,
    children: [
      {
        name: 'patient',
        path: '/patient',
        component: Recherche
      },
      {
        name: 'dossier',
        path: '/patient/dossier',
        component: Dossier,
        props: route => ({ mode: route.query.mode })
      },
      {
        name: 'dossierCreation',
        path: '/patient/dossier/create',
        component: Dossier,
        props: route => ({ mode: route.query.mode })
      },
      {
        name: 'dossierdiab',
        path: '/patient/dossierDiab',
        component: () => import('@/views/Patients/DossierDiab/DossierDiab.vue')
      },
      {
        path: '/patient/consultation/pre-diabetique',
        name: 'Pré-diabétique',
        component: () => import('@/views/Patients/Overview/PreDiabete/PreDiabete.vue')
      },
      {
        path: '/patient/consultation/diabetique',
        name: 'Diabétique',
        component: () => import('@/views/Patients/Overview/Diabetique/Diabetique.vue')
      },
      {
        path: '/patient/consultation/dietetique',
        name: 'Diététique',
        component: () => import('@/views/Patients/Overview/Dietetique/Dietetique.vue')
      },
      {
        path: '/patient/consultation/podo',
        name: 'Podologique',
        component: () => import('@/views/Patients/Overview/Podo/Podo.vue')
      },
      {
        path: '/patient/consultation/podo/observation',
        name: 'Observation',
        component: () => import('@/views/Patients/Overview/Podo/Observation/Observation.vue')
      },
      {
        path: '/patient/consultation/podo/evaluations/:dossierId/:id',
        name: 'Mesure',
        component: () => import('@/views/Patients/Overview/Podo/EvaluationMesure/EvaluationMesure.vue')
      },
      {
        path: '/patient/consultation/pediatrique',
        name: 'Pédiatrique',
        component: () => import('@/views/Patients/Overview/Pediatrique/Pediatrique.vue')
      },
      {
        path: '/patient/:dossierId/consultation/pediatry/situation/:id',
        name: 'EvaluationSituation',
        component: () => import('@/views/Patients/Overview/Pediatrique/EvaluationSituations/EvaluationSituation/EvaluationSituation.vue')
      },
      {
        path: '/patient/:dossierId/consultation/pediatry/entourage/:id',
        name: 'EvaluationEntourage',
        component: () => import('@/views/Patients/Overview/Pediatrique/PediatryEntourages/PediatryEntourage/PediatryEntourage.vue')
      },
      {
        path: '/patient/consultation',
        name: 'Consultation',
        component: Consultation,
        props: route => ({ mode: route.query.mode })
      },
      {
        path: '/patient/rdv',
        name: 'RDV',
        component: () => import('@/views/Patients/RDV/RDV.vue')
      },
      {
        path: '/patient/prescriptions',
        name: 'PatientPrescriptions',
        component: () => import('@/views/Patients/Prescriptions/Prescriptions/Prescriptions.vue')
      },
      {
        path: '/patient/materiel',
        name: 'Matériel',
        component: () => import('@/views/Patients/Materiel/Materiel.vue')
      },
      {
        path: '/patient/:dossierId/prescription/:id',
        name: 'Prescription',
        component: () => import('@/views/Patients/Prescriptions/Prescription/Prescription.vue')
      },
      {
        path: '/patient/document-divers',
        name: 'DocumentDivers',
        component: () => import('@/views/Patients/DocumentDivers/DocumentDivers.vue')
      },
      {
        path: '/patient/document-admin/:id?',
        name: 'DocumentAdmin',
        component: () => import('@/views/Patients/DocumentAdmin/DocumentAdmin.vue')
      },
      {
        path: '/patient/prestation/:id/reports',
        name: 'PrestationReports',
        component: () => import('@/views/Patients/Prescriptions/Reports/Reports.vue')
      },
      {
        path: '/patient/document/rapports/:id',
        name: 'Report',
        component: () => import('@/views/Patients/Documents/Report/Report.vue')
      },
      {
        path: '/patient/consultation/podo/rapports/:id',
        name: 'PodoReport',
        component: () => import('@/views/Patients/Overview/Podo/Reports/Report.vue')
      },
      {
        path: '/patient/prescriptions/rapports/:id',
        name: 'PrescriptionReport',
        component: () => import('@/views/Patients/Prescriptions/PrescriptionReports/Report.vue')
      },
      {
        path: '/patient/prestation/materielrapports/:id',
        name: 'MaterielReport',
        component: () => import('@/views/Patients/Prescriptions/MaterielReports/Report.vue')
      },
      {
        path: '/patient/prestation/coursetgroupesrapports/:id',
        name: 'CourseGroupReport',
        component: () => import('@/views/Patients/Prescriptions/CourseGroupReports/Report.vue')
      },
      {
        path: '/patient/document/evaluation',
        name: 'EvaluationForm',
        component: () => import('@/views/Patients/Documents/EvaluationForm/EvaluationForm.vue')
      }
    ]
  },

  /* --- Agenda --- */
  {
    path: '/agenda',
    component: Index,
    children: [
      {
        name: 'Agenda',
        path: '/agenda',
        component: () => import('@/views/Agenda/Agenda.vue')
      }
    ]
  },

  /* --- Prescriptions --- */
  {
    path: '/prescriptions',
    component: Index,
    children: [
      {
        path: '/prescriptions',
        name: 'Prescriptions',
        component: () => import('@/views/Prescriptions/Recherche/Recherche.vue')
      },
      {
        path: '/prescriptions/renewals',
        name: 'PrescriptionRenewals',
        component: () => import('@/views/Prescriptions/Renewals/Renewals.vue')
      }
    ]
  },

  /* --- Facturation --- */
  {
    path: '/facturation',
    component: Index,
    children: [
      {
        path: '/facturation',
        name: 'Facturation',
        component: () => import('@/views/Facturation/Recherche/Recherche.vue')
      },
      {
        path: '/facturation/generated/:replay?',
        name: 'GeneratedInvoices',
        component: () => import('@/views/Facturation/Generated/Recherche/Recherche.vue')
      },
      {
        path: '/facturation/preview/:id/:documentType',
        name: 'InvoicePreview',
        component: () => import('@/views/Facturation/Facture/Facture.vue')
      }
    ]
  },

  /* --- Heures --- */
  {
    path: '/timbreuse',
    component: Index,
    children: [
      {
        name: 'Timbreuse',
        path: '/timbreuse/:userId?/:date?',
        component: () => import('@/views/Saisie/Timbreuse/Timbreuse.vue')
      }
    ]
  },

  /* --- Administration --- */
  {
    path: '/administration',
    component: Index,
    children: [
      {
        name: 'administration',
        path: '/administration',
        component: () => import(/* webpackChunkName: "administration" */'@/views/Administration/Administration.vue')
      },
      {
        name: 'RoomsManage',
        path: '/administration/rooms',
        component: () => import(/* webpackChunkName: "room" */ '@/views/Administration/Rooms/Room.vue')
      },
      {
        name: 'RoomPlanning',
        path: '/administration/rooms/planning/:planningPeriodId?/:duplicate?',
        component: () => import(/* webpackChunkName: "planning" */ '@/views/Administration/Rooms/Planning/Planning.vue')
      },
      {
        name: 'hoursManage',
        path: '/administration/hoursManage',
        component: () => import(/* webpackChunkName: "hours-manage" */ '@/views/Administration/HoursManage/HoursManage.vue')
      },
      {
        name: 'statistics',
        path: '/administration/statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '@/views/Administration/Statistics/Statistics.vue')
      },
      {
        name: 'ListsManagement',
        path: '/administration/listsManagement',
        component: () => import(/* webpackChunkName: "lists-manage" */ '@/views/Administration/ListsManagement/ListsManagement.vue')
      }
    ]
  },
  {
    path: '/hours',
    component: Index,
    children: [
      {
        name: 'Configuration des heures',
        path: '/administration/hours',
        component: () => import(/* webpackChunkName: "admin-hours" */'@/views/Administration/Hours/Hours.vue')
      },
      {
        name: 'Absences planifiées',
        path: '/administration/absences/:userId',
        component: () => import(/* webpackChunkName: "admin-absence" */'@/views/Administration/Hours/Absences/Absences.vue')
      },
      {
        name: 'Absence Details',
        path: '/administration/absence/:userId/:absenceId',
        component: () => import(/* webpackChunkName: "admin-absence-details" */'@/views/Administration/Hours/Absence/Absence.vue')
      }
    ]
  }

]
