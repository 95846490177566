





















































































































import { IDateRange } from '@/models/common-models'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class DateRange extends Vue {
  @Prop({ default: undefined }) public value!: IDateRange
  @Prop({ default: '' }) public rules!: string
  @Prop({ default: '' }) public nameStart!: string
  @Prop({ default: '' }) public nameEnd!: string
  @Prop({ default: undefined }) public vidStart!: string
  @Prop({ default: undefined }) public vidEnd!: string
  @Prop({ default: '' }) public label!: string
  @Prop({ default: 'mdi-calendar-range' }) public appendicon!: string
  @Prop({ default: true }) public readonly!: boolean
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: null }) public clearable!: boolean
  @Prop({ default: '' }) public minDate!: string
  @Prop({ default: '' }) public maxDate!: string
  @Prop({ default: 'Début' }) public startTitle!: string
  @Prop({ default: 'Fin' }) public endTitle!: string
  @Prop({ default: 'date' }) public datePickerType!: string
  @Prop() public id!: string
  @Prop({ default: true }) public updatePickerTo!: boolean
  @Prop({ default: 0 }) public firstDayofWeek!: number

  public focus = false
  public hasError = false
  public clearableProp = false
  public errors: string[] = []

  public innerDateFrom = ''
  public innerDateTo = ''

  public dateFormatted = ''
  public dateRDVMenu = false

  public pickerDateFrom = ''
  public pickerDateTo = ''

  @Watch('innerDateFrom') onChange () {
    if (this.updatePickerTo) {
      this.pickerDateTo = this.innerDateFrom
    }
    this.$emit('input', { from: this.innerDateFrom, to: this.innerDateTo })
  }

  @Watch('innerDateTo') onChangeTo () {
    this.$emit('input', { from: this.innerDateFrom, to: this.innerDateTo })
  }

  @Watch('value') onValueChanges () {
    this.resetValue()
  }

  public get hasValueFrom () {
    return !!this.innerDateFrom
  }

  public get hasValueTo () {
    return !!this.innerDateFrom
  }

  public get requiredClass () {
    return this.rules?.includes('required') || this.rules?.includes('dateRangeFromRequired') || this.rules?.includes('dateRangeToRequired') ? 'required' : ''
  }

  public get toRules () {
    return this.selectRule('dateRangeToRequired')
  }

  public get fromRules () {
    return this.selectRule('dateRangeFromRequired')
  }

  private selectRule (rule: string) {
    return this.rules?.includes(rule) ? 'required' : ''
  }

  public getObserverErrors (errors: any) {
    for (const prop in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, prop)) {
        if (errors[prop].length > 0) {
          return [errors[prop][0]]
        }
      }
    }
    return []
  }

  private onFromClearClick () {
    if (this.fromRules.length <= 0) {
      this.innerDateFrom = ''
      this.getDateRangeFormatted(this.innerDateFrom, this.innerDateTo)
    }
  }

  private onToClearClick () {
    if (this.toRules.length <= 0) {
      this.innerDateTo = ''
      this.getDateRangeFormatted(this.innerDateFrom, this.innerDateTo)
    }
  }

  public mounted () {
    if (this.clearable === null && this.rules !== 'required') {
      this.clearableProp = true
    } else {
      this.clearableProp = this.clearable
    }

    this.resetValue()
  }

  public resetValue () {
    if (this.value) {
      this.innerDateFrom = this.value.from!
      this.innerDateTo = this.value.to!
    } else {
      this.innerDateFrom = ''
      this.innerDateTo = ''
    }

    this.getDateRangeFormatted(this.innerDateFrom, this.innerDateTo)
    this.$emit('change', this.value)
  }

  public clearDates () {
    this.innerDateFrom = ''
    this.innerDateTo = ''
  }

  public getDateRangeFormatted (dateF, dateT) {
    if (this.datePickerType === 'month') {
      if (dateF) {
        dateF = new Date(dateF.trim()).toLocaleString(window.navigator.language, { year: 'numeric', month: 'short' })
      }
      if (dateT) {
        dateT = new Date(dateT.trim()).toLocaleString(window.navigator.language, { year: 'numeric', month: 'short' })
      }
      if (dateF && dateT) {
        this.dateFormatted = 'De ' + dateF + ' à ' + dateT
      } else if (dateF) {
        this.dateFormatted = 'Depuis ' + dateF
      } else if (dateT) {
        this.dateFormatted = 'Jusqu\'à ' + dateT
      } else {
        this.dateFormatted = ''
      }
    } else {
      if (dateF) {
        dateF = new Date(dateF.trim()).toLocaleDateString(window.navigator.language)
      }
      if (dateT) {
        dateT = new Date(dateT.trim()).toLocaleDateString(window.navigator.language)
      }
      if (dateF && dateT) {
        this.dateFormatted = 'Du ' + dateF + ' au ' + dateT
      } else if (dateF) {
        this.dateFormatted = 'Depuis le ' + dateF
      } else if (dateT) {
        this.dateFormatted = 'Jusqu\'au ' + dateT
      } else {
        this.dateFormatted = ''
      }
    }
  }
}
