import { AuthService } from '@/services/auth-service'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AuthCallback extends Vue {
  public error = false;
  private authService = AuthService.getInstance();

  public async mounted () {
    // check for error
    if (this.$route.fullPath.indexOf('error') >= 0) {
      this.error = true
      return
    }

    if (this.$route.fullPath.indexOf('code') >= 0) {
      await this.authService.completeAuthentication()
      this.$router.push({ name: 'patient' })
    }

    const url = sessionStorage.getItem('redirectUrl')
    if (url) {
      sessionStorage.removeItem('redirectUrl')
      if ((url !== '/') && (url !== '/patient')) {
        this.$router.push(url)
      }
    } else {
      this.$router.push({
        name: 'patient'
      })
    }
  }
}
