import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import { districts, professionnelTypes, representantTypes } from '@/views/Patients/Dossier/Constants'
import Commons from '../../Helpers/commons'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert
  }
})
export default class ReseauDialog extends Vue {
  @Prop()
  public visible!: boolean

  @Prop()
  public isLoading!: boolean

  @Prop({ default: 'Reseau' })
  public title!: string

  @Prop({ default: 'fam' })
  public mode!: string

  @Prop({ default: {} })
  public editedItem!: any

  @Prop({ default: false })
  public isEdit!: boolean

  @Prop()
  public errorMessages!: string[]

  @Prop()
  public professionalsList!: any[]

  @Prop({ default: false })
  public searchLoading!: boolean

  @Watch('isEdit')
  public onChange () {
    if (this.isEdit) {
      this.$parent.$emit('search', { type: this.editedItem.reseauMedicalExtra?.type, searchTerm: '' })
    }
  }

  @Watch('professionalsList')
  public onListChange (val: any[]) {
    if (val.length > 0) {
      this.model = this.professionalsList.find(emp => emp.fullname === this.editedItem?.fullname) ?? {}
    } else {
      this.model = {}
    }
  }

  public model: any = {}
  public districts = districts
  public representantTypes = representantTypes
  public professionnelTypes = professionnelTypes

  get show () {
    return this.visible
  }

  get isPro () {
    return this.mode === 'pro'
  }

  get isFam () {
    return this.mode === 'fam'
  }

  get isDoctor () {
    return (this.editedItem.reseauMedicalExtra?.type === 3 ||
      this.editedItem.reseauMedicalExtra?.type === 4 ||
      this.editedItem.reseauMedicalExtra?.type === 7)
  }

  get showSearch () {
    return this.editedItem.reseauMedicalExtra?.type !== 6
  }

  get isReadonly () {
    return (this.isPro && this.editedItem.reseauMedicalExtra?.type !== 6 &&
    (typeof this.model?.linkedId !== 'undefined' || this.isEdit) && this.editedItem?.linkedId > 0)
  }

  public TypeChanges (idx: number) {
    this.$parent.$emit('search', { type: idx, searchTerm: '' })
    this.$emit('reset-edit')
    this.model = {}
    if (!idx) {
      this.resetForm()
    }
  }

  public onProfessionalSelect () {
    this.$emit('professional-select', this.model)
  }

  public async emitSaveClick () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid || this.isReadonly) {
      observer.reset()
      this.$parent.$emit('save', this.editedItem)
    } else if (!isValid) {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  private resetForm () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
  }

  public emitClose () {
    this.resetForm()
    this.TypeChanges(0)
    this.$emit('close')
    this.$parent.$emit('close-errors')
  }
}
