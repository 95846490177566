import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import fr from 'vuetify/src/locale/fr'
import '@/sass/overrides.scss'
import '@/sass/global.scss'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#CC3333',
        secondary: '#00897B',
        accent: '#EF9A9A',
        error: '#B71C1C'
      },
      dark: {
        primary: '#CC3333'
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  }
})
