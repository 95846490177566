import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DossierHeaders, DossierNavItems, DossierNavItemsNoCreate } from '../Static'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { PatientService } from '@/services/patient-service'
import { dossierSearchRequest } from '@/models/dossier-search-request'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { districts } from '../Dossier/Constants'
import Commons from '@/components/shared/Helpers/commons'
import ImportantAppointmentInfo from '@/components/shared/ImportantAppointmentInfo/ImportantAppointmentInfo.vue'
import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import { defaultItemsPerPage, defaultItemsPerPageWithoutAll } from '@/shared/constants/Constants'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert,
    ImportantAppointmentInfo
  }
})
export default class Recherche extends Vue {
  private layoutService = LayoutService.getInstance()
  private patientsService = PatientService.getInstance()
  private userService = AuthService.getInstance()

  public tab = null
  public tabs = ['Recherche de dossier', 'Documents à télécharger']
  public searchModel: dossierSearchRequest = {
    dateRange: {
      from: Commons.GetTodayFormatted(),
      to: Commons.GetTodayFormatted()
    },
    isActive: true
  }

  public today = Commons.GetTodayFormatted()
  public menuDateRDV = false
  public headers = DossierHeaders
  public dossiers: any = []
  public districts = districts
  public allInfirmieres: AppUser[] | any = []

  public options: any = {}
  public defaultItemsPerPageWithoutAll = defaultItemsPerPageWithoutAll
  public defaultItemsPerPage = defaultItemsPerPage
  public totalSize = 0
  public pageCount = 0

  public consultationTypes = [
    { value: 1, text: 'Diabétique' },
    { value: 2, text: 'Diététique' },
    { value: 3, text: 'Pré-diabète' },
    { value: 4, text: 'Pédiatrie' },
    { value: 5, text: 'Soins des pieds' }
  ]

  public statuses = [
    { value: null, text: 'Tous' },
    { value: false, text: 'Inactif' },
    { value: true, text: 'Actif' }
  ]

  isLoading = false
  errorMessages: string[] = []
  public firstSearch = true
  public isReadyToSearch = false

  public async mounted () {
    if (ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
      this.layoutService.updateDrawerList(DossierNavItems)
    } else {
      this.layoutService.updateDrawerList(DossierNavItemsNoCreate)
    }
    if (!ModuleAuthorisationManager.HasAccess('documentsToDownload')) {
      this.tabs = ['Recherche de dossier']
    }

    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    })
    // wait a frame and then after the first updated cycle, everything should be rendered and the options object
    // should have a value and we can therefore perform our initial default search
    await this.$nextTick()
    this.$once('hook:updated', () => {
      this.isReadyToSearch = true
      this.search()
    })
  }

  private async getSearchData (query?: string) {
    // prevent multiple searches from being sent, since searches can be issued from clicking the search button, v-data-table options changing, ...
    if (this.isLoading) {
      return
    }
    if (this.firstSearch) {
      this.firstSearch = false
      this.options.sortBy = ['nextRdv']
      this.options.sortDesc = [false]
    }
    if (!this.isReadyToSearch) {
      return
    }
    this.isLoading = true
    await this.patientsService.getPatients(query).then((ds: any) => {
      this.dossiers = ds.value
      this.totalSize = ds.size
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isLoading = false
    })
  }

  private buildQueryString () {
    const queryString: string[] = []
    if (this.searchModel.patientCode) queryString.push(`search=patientCode eq ${this.searchModel.patientCode}`)
    if (this.searchModel.firstName) queryString.push(`search=prenom has ${this.searchModel.firstName.trim()}`)
    if (this.searchModel.lastName) queryString.push(`search=nom has ${this.searchModel.lastName.trim()}`)
    if (this.searchModel.birthDate) {
      queryString.push(`search=dob eq ${new Date(this.searchModel.birthDate.trim()).toLocaleDateString('fr-CH')}`)
    }
    if (this.searchModel.telephone) {
      const tel = this.searchModel.telephone.replaceAll(' ', '').replaceAll('+', '%2B')
      queryString.push(`search=mobile,telephone_private,telephone_work eq ${tel}`)
    }
    if (this.searchModel.npa) queryString.push(`search=npa eq ${this.searchModel.npa.trim()}`)
    if (this.searchModel.city) queryString.push(`search=city has ${this.searchModel.city.trim()}`)
    if (this.searchModel.district?.length) {
      queryString.push(`search=district eq ${this.searchModel.district}`)
    }
    if (this.searchModel.intervenant) queryString.push(`search=intervenant ~appointments eq ${this.searchModel.intervenant.trim()}`)
    if (this.searchModel.consultationType) queryString.push(`search=consultationsType ~appointments eq ${this.searchModel.consultationType}`)
    if (this.searchModel.dateRange) {
      if (this.searchModel.dateRange.from) {
        queryString.push(`search=dateRDV ~appointments ge ${new Date(this.searchModel.dateRange.from.trim()).toLocaleDateString('fr-CH')}`)
      }
      if (this.searchModel.dateRange.to) {
        queryString.push(`search=dateRDV ~appointments le ${new Date(this.searchModel.dateRange.to.trim()).toLocaleDateString('fr-CH')}`)
      }
    }
    if (typeof this.searchModel.isActive === "boolean") {
      queryString.push(`search=isActive eq ${this.searchModel.isActive}`)
    } else if (this.searchModel.isActive !== null) {
      queryString.push(`search=isActive eq true`)
    }
    Commons.PushPagingOptionsToQuerystring(queryString, this.options)
    if (this.options.sortBy.length === this.options.sortDesc.length) {
      this.options.sortBy.forEach((e, i) => {
        if (e === 'fullName') {
          const p1 = 'nom'
          const p2 = 'prenom'
          let s1 = `orderBy=${p1}`
          let s2 = `orderBy=${p2}`
          if (this.options?.sortDesc[i] === true) {
            s1 += ' desc'
            s2 += ' desc'
          }
          queryString.push(s1)
          queryString.push(s2)
        } else {
          let s = `orderBy=${e}`
          if (this.options?.sortDesc[i] === true) {
            s += ' desc'
          }
          queryString.push(s)
        }
      })
    }
    return queryString.join('&')
  }

  public async search () {
    await this.getSearchData(this.buildQueryString())
  }

  public async searchDossier () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      await this.search()
      this.options.page = 1
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public resetSearch () {
    this.searchModel = { isActive: true }
    this.search()
  }

  public editItem (item: any) {
    this.patientsService.updateDossierSelected(item)
    this.$router.push({ name: 'dossier' })
  }

  public getConsultationType (consultationTypeId: number) {
    return this.consultationTypes.find(x => x.value === Number(consultationTypeId))?.text
  }

  public getIntervenant (intervenantId: string) {
    return this.allInfirmieres.find(x => x.id === intervenantId)?.fullName
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.layoutService.updateDrawerList([])
  }
}
