













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditablePreview extends Vue {
  @Prop({ default: 'text' }) public type!: string
  @Prop({ default: '' }) public preview!: string
  @Prop({ default: false }) public required!: boolean
  @Prop() public id!: string
}
