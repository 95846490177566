/* eslint-disable @typescript-eslint/camelcase */
import { AppUser } from '@/models/app-user-dto'
import Oidc, { UserManager, User, UserManagerSettings } from 'oidc-client'
import { BehaviorSubject } from 'rxjs'
import http from '@/http-client'
import { UserInfoUpdateModel, UserPasswordUpdateModel } from '@/models/user-info-update'
import { AxiosResponse } from 'axios'
import { ErrorService } from './error.service'

export class AuthService {
  private manager: UserManager
  private loggedInUser!: User | null
  private _isAuthSource = new BehaviorSubject<boolean>(false)
  private _isLoading = new BehaviorSubject<boolean>(false)
  private _user = new BehaviorSubject<string>('')
  private static instance: AuthService
  public isAuth$ = this._isAuthSource.asObservable()
  public isLoading$ = this._isLoading.asObservable()
  public $user = this._user.asObservable()
  private currentAppUser: AppUser | null = null

  constructor () {
    const oidcSetting: UserManagerSettings = {
      authority: process.env.VUE_APP_AUTH_URL,
      client_id: process.env.VUE_APP_AUTH_ID,
      redirect_uri: window.location.origin + '/auth-callback',
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      scope: 'openid profile email api.access',
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: false,
      silent_redirect_uri: window.location.origin + '/silent-refresh.html'
    }
    Oidc.Log.logger = console
    Oidc.Log.level = Oidc.Log.INFO

    this.manager = new UserManager(oidcSetting)
    this.manager.getUser().then((user) => {
      this.loggedInUser = user
      this._isAuthSource.next(this.isAuthenticated())
    })

    this.manager.events.addAccessTokenExpiring(async () => {
      await this.loginSilient()
    })
  }

  public static getInstance (): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService()
    }
    return AuthService.instance
  }

  pushLoadingState (isloading: boolean) {
    this._isLoading.next(isloading)
  }

  async login (redirect?: string) {
    await this.manager.clearStaleState()
    sessionStorage.setItem('redirectUrl', redirect || window.location.pathname)
    return this.manager.signinRedirect().catch((err) => {
      return console.log('signin err', err)
    })
  }

  async loginSilient () {
    this.loggedInUser = await this.manager.signinSilent()
    this._isAuthSource.next(this.isAuthenticated())
  }

  async completeAuthentication () {
    this.loggedInUser = await this.manager.signinRedirectCallback()
    this._isAuthSource.next(this.isAuthenticated())
  }

  register (userRegistration: any) {
    return http.post('/account/register', userRegistration)
  }

  updateUser (user: AppUser) {
    return http.put(`/account/user/${user.id}`, user)
  }

  updateUserInfo (user: UserInfoUpdateModel): Promise<AxiosResponse<any>> {
    return http.put(`/account/updateUserInfo/${user.subId}`, user)
  }

  public async ChangePassword (user: UserPasswordUpdateModel) {
    const response = await http.put(`/account/changePassword/${user.subId}`, user)
    return { status: response.status, data: response.data }
  }

  deleteUser (user: AppUser) {
    return http.delete(`/account/user/${user.id}`)
  }

  getUser (): any {
    return this.loggedInUser?.profile
  }

  async getAppUser (errorMessages: string[]): Promise<AppUser | null> {
    const user = this.getUser()
    if (this.currentAppUser === null && user) {
      await this.getAllUsers().then(allUsers => {
        const found = allUsers.find(x => x.id === user.sub)
        if (found) {
          this.currentAppUser = found
        }
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        errorMessages.length = 0
        res.errors.forEach(error => {
          errorMessages.push(error)
        })
      })
    }
    return this.currentAppUser
  }

  updateAuthEmail (email: string) {
    this._user.next(email)
  }

  async getAllUsers (): Promise<AppUser[]> {
    const res = await http.get('account/users')
    return res.data as AppUser[]
  }

  async getUserById (id: string): Promise<AppUser> {
    const res = await http.get(`account/getUser/${id}`)
    return res.data as AppUser
  }

  async getAllInfirmiereUsers (): Promise<AppUser[]> {
    const res = await http.get('account/users/infirmieres')
    return res.data as AppUser[]
  }

  async getAllInfirmiereGroupUsers (activeOnly?: boolean): Promise<AppUser[]> {
    const res = await http.get(`account/users/allinfirmieres/${activeOnly ?? ''}`)
    return res.data as AppUser[]
  }

  isAuthenticated (): boolean {
    return this.loggedInUser != null && !this.loggedInUser.expired
  }

  async getAuthorizationHeaderValue (): Promise<string> {
    if (this.loggedInUser?.expired) {
      await this.loginSilient()
    }
    return `${this.loggedInUser?.token_type} ${this.loggedInUser?.access_token}`
  }

  getAccessToken (): string {
    if (this.loggedInUser?.expired) {
      return ''
    }
    return this.loggedInUser?.access_token!
  }

  get name () {
    return this.loggedInUser?.profile?.email
  }

  get fullName (): string {
    const user = this.getUser()
    return user.family_name.concat(' ', user.given_name)
  }

  get SubId (): string {
    return this.loggedInUser != null ? this.loggedInUser.profile.sub : ''
  }

  get Roles (): string[] {
    return this.loggedInUser?.profile?.role || []
  }

  get GroupIds (): string {
    return this.loggedInUser?.profile?.groupIds || ''
  }

  async signout () {
    await this.manager.signoutRedirect()
  }
}
