import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import ReseauDialog from '@/components/shared/Dialogs/ReseauDialog/ReseauDialog.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { reseaux } from '../../Static'
import { professionnelTypes } from '../Constants'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    ValidationObserver,
    Confirm,
    ReseauDialog
  }
})
export default class InfoSocial extends Vue {
  @Prop()
  public isLoading!: boolean

  @Prop()
  public errorMessages!: string[]

  @Prop()
  public searchLoading!: boolean

  @Prop()
  public professionalsList!: any[]

  @Prop()
  public dossierId!: string

  private professionnelTypes = professionnelTypes
  public reseaux = reseaux
  public dialog = false
  public showConfirm = false
  public isEdit = false
  public editedItem: any = {}
  public mode = ''
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    this.resetEditingItem()
  }

  @Watch('isLoading')
  valChanged (loading: boolean) {
    if (!loading && this.errorMessages.length === 0) {
      this.closeReseauDialog()
    }
  }

  public convertTypeIdtoName (item: any) {
    if (!item) return
    if (item.type === 6) {
      return item.fonction
    } else {
      return this.professionnelTypes.find(x => x.value === item.type)?.text
    }
  }

  public getFullName (item: {nom: string; prenom: string }) {
    return `${item?.nom} ${item?.prenom} ${this.getEmployerName(item)}`
  }

  private getEmployerName (item: any) {
    return !item.reseauMedicalExtra?.employeur ? '' : (item.nom as string).trim().length === 0 ? `${item.reseauMedicalExtra?.employeur}` : ''
  }

  get dialogTitle () {
    const type = this.mode === 'pro' ? 'Réseau professionnel' : 'Réseau familial'
    return this.isEdit ? `Modifier l'entrée du ${type}` : `Ajouter une nouvelle entrée de ${type}`
  }

  public openReseauDialog (mode: string) {
    this.mode = mode
    this.dialog = true
    this.$emit('close-errors')
  }

  public editItem (item: any, mode: string) {
    if (mode === 'fam') item.reseauMedicalExtra = {}
    this.setSelectedItem(item)
    this.mode = mode
    this.dialog = true
    this.$emit('close-errors')
  }

  private setSelectedItem (item: any) {
    this.isEdit = true
    this.editedItem = Object.assign({}, item)
  }

  public displayDeleteConfirmDialog (item: any) {
    this.setSelectedItem(item)
    this.showConfirm = true
  }

  public async confirmDeleteCallback (value: any) {
    this.showConfirm = false
    if (value) {
      this.editedItem.linkedId
        ? this.$emit('delete-linkedprof', this.dossierId, this.editedItem.linkedId)
        : this.$emit('delete', this.editedItem.reseauAutreId)
    }
    this.resetEditingItem()
  }

  public closeReseauDialog () {
    this.dialog = false
    this.isEdit = false
    this.resetEditingItem()
  }

  public resetEditingItem () {
    this.editedItem = { isEmergencyContact: false, reseauMedicalExtra: { isActive: true } }
  }

  public professionalSelected (selectedItem: any) {
    const reseauId = this.editedItem.reseauMedicalExtra.reseauId
    const typeId = this.editedItem.reseauMedicalExtra.type
    if (!selectedItem) {
      this.resetEditingItem()
    } else {
      this.editedItem = selectedItem
    }
    this.editedItem.reseauMedicalExtra.reseauId = reseauId
    this.editedItem.reseauMedicalExtra.type = typeId
    this.editedItem.reseauMedicalExtra.isActive = true
  }

  public Close () {
    this.$emit('close')
  }
}
