






































import { Component, Vue, Prop } from 'vue-property-decorator'
import Item from '@/components/shared/DraweItem/Item.vue'
import { DrawerItem } from './Models/drawer-model'

@Component({
  components: {
    Item
  }
})
export default class ItemGroup extends Vue {
  public model = true

  @Prop()
  public item!: DrawerItem

  @Prop({ default: false })
  public subGroup!: boolean

  @Prop({ default: false })
  public text!: boolean

  get children () {
    return this.item.children
  }

  get computedText () {
    if (!this.item || !this.item.title) return ''

    let text = ''

    this.item.title.split(' ').forEach(val => {
      text += val.substring(0, 1)
    })

    return text
  }

  get group () {
    return this.genGroup(this.item.children as DrawerItem[])
  }

  genGroup (children: DrawerItem[]) {
    return children
      .filter(item => item.to)
      .map(item => {
        const parent = this.item.group
        let group = `${parent}/${item.to}`

        if (item.children) {
          group = `${group}|${this.genGroup(item.children)}`
        }

        return group
      }).join('|')
  }
}
