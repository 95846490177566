export interface EvaluationTblStructureModel {
  id: number;
  row: string;
  values?: {
      id: number ;
      value: string;
      suffix?: string;
  }[];
  separator?: boolean;
}

export class EvaluationInitialeHelper {
  public static getTblStructure (): EvaluationTblStructureModel[] {
    return [
      {
        id: 1,
        row: 'Évaluation OPAS 7<span class="note">, al 1a</span>',
        values: [
          {
            id: 1,
            value: '',
            suffix: 'h'
          },
          {
            id: 2,
            value: '',
            suffix: 'h'
          },
          {
            id: 3,
            value: '',
            suffix: 'h'
          }
        ]
      },
      {
        id: 2,
        row: 'Conseils OPAS 7<span class="note">, al 2a 2</span>',
        values: [
          {
            id: 1,
            value: '',
            suffix: 'h'
          },
          {
            id: 2,
            value: '',
            suffix: 'h'
          },
          {
            id: 3,
            value: '',
            suffix: 'h'
          }
        ]
      },
      {
        id: 3,
        row: 'Examens et soins OPAS 7<span class="note">, al 2b 10</span><br /><em class="note">(soins des pieds pour diabétiques)</em>',
        values: [
          {
            id: 1,
            value: '',
            suffix: 'h'
          },
          {
            id: 2,
            value: '',
            suffix: 'h'
          },
          {
            id: 3,
            value: '',
            suffix: 'h'
          }
        ]
      },
      {
        id: -1,
        row: '',
        separator: true,
        values: []
      },
      {
        id: 4,
        row: 'Nombre total des prestations OPAS 7',
        values: [
          {
            id: 1,
            value: ''
          },
          {
            id: 2,
            value: ''
          },
          {
            id: 3,
            value: ''
          }
        ]
      }
    ]
  }
}
