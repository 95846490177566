import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import Commons from '../../Helpers/commons'
import { AssuranceHistoryResponse } from '@/models/insurances-model'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Alert
  }
})
export default class InsuranceHistoryDialog extends Vue {
  @Prop()
  public visible!: boolean

  @Prop()
  public isLoading!: boolean

  @Prop({ default: { isActive: true } })
  public insuranceItem!: AssuranceHistoryResponse

  @Prop({ default: [] })
  public errorMessages!: string[]

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public allAssurances!: any[]

  get show () {
    return this.visible
  }

  @Watch('visible')
  public modelChanges () {
    if (!this.visible) {
      this.resetDialog()
    }
  }

  public async emitSaveClick () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.$emit('saveClick')
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public emitClose () {
    this.$emit('close')
  }

  private resetDialog () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    observer.reset()
  }
}
