import { InfosImportantModel } from '@/components/shared/Dialogs/InfoImportantDialog/InfoImportant'
import http from '@/http-client'
import { FullNavItems, FullNavItemsNoCreate, FullNavItemsNoCreateNoOverview, FullNavItemsNoOverview } from '@/views/Patients/Static'
import { ModuleAuthorisationManager } from './module-authorisation-manager'

export class PatientInfoService {
  private static instance: PatientInfoService

  public static getInstance (): PatientInfoService {
    if (!PatientInfoService.instance) {
      PatientInfoService.instance = new PatientInfoService()
    }
    return PatientInfoService.instance
  }

  // EspaceInfoImportant
  async getEspaceInfoItem (dossierId: string) {
    const response = await http.get(`/dossierPatient/espace-info/${dossierId}`)
    const count = (response.data as InfosImportantModel[]).length
    FullNavItems[2].infoImportantCount = count
    FullNavItemsNoCreate[1].infoImportantCount = count
    FullNavItemsNoOverview[2].infoImportantCount = count
    FullNavItemsNoCreateNoOverview[1].infoImportantCount = count
    return (response.data) as InfosImportantModel[]
  }

  async addEspaceInfoItem (dossierId: string, espaceInfo: InfosImportantModel) {
    espaceInfo.dossierId = dossierId
    const response = await http.post(`/dossierPatient/espace-info/${dossierId}`, espaceInfo)
    return response.data as InfosImportantModel
  }

  async deleteEspaceInfoItem (espaceInfoItemId: string) {
    const response = await http.delete(`/dossierPatient/espace-info/${espaceInfoItemId}`)
    return response.data as number
  }
}
