import { Component, Prop, Vue } from 'vue-property-decorator'
import { statusDossier, statusMembre, statusOFAS } from '../Constants'
import { ValidationObserver } from 'vee-validate'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'

@Component({
  components: {
    ValidationObserver
  }
})
export default class InfoAdmin extends Vue {
  @Prop() public dossier!: any

  public statusOFAS = statusOFAS
  public statusMembre = statusMembre
  public statusDossier = statusDossier

  private userService = AuthService.getInstance()
  private displayIsActive = false
  private disableIsActive = true

  private initialMembershipStatus = 0

  public mounted () {
    this.displayIsActive = this.userService.Roles.includes('AdministrationStatutAndDisablingDossierPatient') ||
      this.userService.Roles.includes('AdministrationStatutAndDisablingDossierPatient_ReadOnly')
    this.disableIsActive = !this.userService.Roles.includes('AdministrationStatutAndDisablingDossierPatient')
    this.initialMembershipStatus = this.dossier.memberStatusEnumId
  }

  public async Save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.$emit('save', this.dossier)
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public Close () {
    this.$emit('close')
  }

  get isDeceased () {
    return this.dossier.statusEnumId === 2
  }

  get isMember () {
    return !!this.dossier.memberStatusEnumId
  }

  get isCancelled () {
    return this.dossier.statusEnumId === 3
  }

  get wasMember () {
    const { dossier, initialMembershipStatus } = this
    const membershipStatuses = [1, 2, 3, 4, 5]
    const isNotMember = !membershipStatuses.includes(dossier.memberStatusEnumId)
    const isInitialMember = membershipStatuses.includes(initialMembershipStatus)
    const isFormerMember = !dossier.memberStatusEnumId || !membershipStatuses.includes(dossier.memberStatusEnumId)
    return isNotMember || (isInitialMember && isFormerMember)
  }
}
