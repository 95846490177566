export enum UserRoles {
    Creators = 'creators',

    // patients du jour + search
    ViewJourPatients = 'viewjourpatients',
    // includes editing patients du jour
    Editpatients = 'editjourpatients',
    SearchPatients = 'searchpatients',

    // admin data
    ViewAdminpage = 'viewadminpage',
    EditAdminPage = 'editadminpage',

    // nurse data
    ViewNurseData = 'viewnursedata',
    EditNurseData = 'editnursedata',

    // agenda
    ViewAgenda = 'viewagenda',
    EditAgenda = 'Editagenda',

    // prescriptions
    ViewPrescriptions = 'viewprescriptions',
    EditPrescriptions = 'editprescriptions'
  }

export enum RoleGroup {
  Administration = 1,
  Gestion,
  Cliniciennes,
  Infirmières,
  Diététitiennes,
  DirectionLDS,
  ComptaLDS,
  Informatique
}
