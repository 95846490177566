import { AuthService } from './auth-service'
import { RoleGroup } from '../shared/constants/role-enums'

export class ModuleAuthorisationManager {
  private static permissionMap = [
    {
      name: 'dossier.patientCreation',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.Informatique]
    },
    {
      name: 'dossier.dossierPatientOverview',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.DirectionLDS, RoleGroup.Informatique]
    },
    {
      name: 'prescription.createPrescriptionRenewals',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.ComptaLDS, RoleGroup.Informatique]
    },
    {
      name: 'timbreuse',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.DirectionLDS, RoleGroup.ComptaLDS, RoleGroup.Informatique]
    },
    {
      name: 'documentsToDownload',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.DirectionLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.statusAndDeactivatePatient',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.DirectionLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.statistics',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.DirectionLDS, RoleGroup.ComptaLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.hoursManagementAndExport',
      groups: [RoleGroup.Gestion, RoleGroup.Cliniciennes, RoleGroup.Infirmières, RoleGroup.Diététitiennes, RoleGroup.DirectionLDS, RoleGroup.ComptaLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.userManagement',
      groups: [RoleGroup.Administration, RoleGroup.Gestion, RoleGroup.DirectionLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.hoursConfiguration',
      groups: [RoleGroup.Gestion, RoleGroup.DirectionLDS, RoleGroup.Informatique]
    },
    {
      name: 'admin.roomManage.writeOperations',
      groups: [RoleGroup.Gestion, RoleGroup.DirectionLDS, RoleGroup.ComptaLDS, RoleGroup.Informatique]
    }
  ]

  public static HasAccess (pageName: string) {
    return this.HasAccessInternal(pageName, AuthService.getInstance().GroupIds.split(':').map(g => parseInt(g, 10)))
  }

  private static HasAccessInternal (pageName: string, groups: number[]) {
    const module = this.permissionMap.find(p => p.name === pageName)
    if (module) {
      return module.groups?.some(g => groups.includes(g))
    }
    return false
  }
}
