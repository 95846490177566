import { Component, Vue, Prop } from 'vue-property-decorator'
import { DrawerItem } from '@/components/shared/Models/drawer-model'
import InfoImportantDialog from '@/components/shared/Dialogs/InfoImportantDialog/InfoImportant.vue'
import ImportantAppointmentDialog from '@/components/shared/Dialogs/ImportantAppointmentDialog/ImportantAppointmentDialog.vue'

@Component({
  components: {
    InfoImportantDialog,
    ImportantAppointmentDialog
  }
})
export default class Item extends Vue {
  @Prop()
  public item!: DrawerItem

  @Prop() public text!: boolean

  public showInfoDialog = false
  public showAppointmentInfoDialog = false

  get href () {
    return this.item.href
  }

  get showInfoIndicator () {
    return !(typeof this.item.infoImportantCount === 'undefined')
  }

  get showAppointmentInfoIndicator () {
    return !(typeof this.item.appointmentsWithImportantInfoCount === 'undefined')
  }

  public openEspaceInfoDialog () {
    this.showInfoDialog = true
  }

  public close () {
    this.showInfoDialog = false
  }

  public openAppointmentInfoDialog () {
    this.showAppointmentInfoDialog = true
  }

  public closeAppointmentInfoDialog () {
    this.showAppointmentInfoDialog = false
  }
}
