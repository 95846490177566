














































import { ValidationProvider } from 'vee-validate'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ValidationProvider
  }
})
export default class SelectInput extends Vue {
  @Prop({ default: '' }) public value!: string
  @Prop({ default: '' }) public rules!: string
  @Prop({ default: '' }) public name!: string
  @Prop({ default: '' }) public label!: string
  @Prop({ default: undefined }) public vid!: string | undefined
  @Prop() public items!: string[] | any[]
  @Prop({ default: false }) public multiple!: boolean
  @Prop({ default: false }) public returnObject!: boolean
  @Prop({ default: false }) public readonly!: boolean
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: false }) public hideDetails!: boolean
  @Prop() public id!: string
  @Prop({ default: 'text' }) itemText!: string
  @Prop({ default: 'value' }) itemValue!: string
  @Prop({ default: false }) loading!: boolean
  @Prop({ default: null }) public clearable!: boolean
  @Prop({ default: false }) allowNullInitialValue!: boolean

  public clearableProp = false
  public focus = false
  public hasError = false

  @Watch('innerValue') onChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value') onValueChanges (val: string) {
    if (val !== this.innerValue) {
      this.innerValue = val
    }
  }

  public innerValue = ''

  public get hasValue () {
    return !!this.innerValue
  }

  public get requiredClass () {
    return this.rules?.includes('required') ? 'required' : ''
  }

  public emitAppendClick () {
    this.$emit('appendiconClick')
  }

  public changed (event: any) {
    this.$emit('change', event)
  }

  public mounted () {
    if (this.allowNullInitialValue || (this.value !== undefined && this.value !== null && this.value.constructor === Number) || this.value) {
      this.innerValue = this.value
    }

    if (this.clearable === null && !this.readonly && this.requiredClass !== 'required') {
      this.clearableProp = true
    } else {
      this.clearableProp = this.clearable
    }
  }
}
