/* eslint-disable */
import './class-component-hooks'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http, {fHttp} from './http-client'
import { AuthService } from './services/auth-service'
import vuetify from './plugins/vuetify'
import './plugins/tiptap-vuetify'
import { setInteractionMode } from 'vee-validate'
import TextInput from '@/components/shared/TextInput.vue'
import SelectInput from '@/components/shared/SelectInput.vue'
import ComboInput from '@/components/shared/ComboInput.vue'
import TextareaInput from '@/components/shared/TextareaInput.vue'
import AutoComplete from '@/components/shared/AutoComplete.vue'
import DocDate from '@/components/shared/DocDate.vue'
import LimitedChar from '@/components/shared/LimitedChar.vue'
import DocAddressDr from '@/components/shared/DocAddressDr.vue'
import EditablePreview from '@/components/shared/EditablePreview.vue'
import DateRange from '@/components/shared/DateRange.vue'
import { VueMaskDirective } from 'v-mask'
import '@/components/shared/global-components'
import Commons from './components/shared/Helpers/commons'
import TextareaEditor from '@/components/shared/TextareaEditor.vue'
import VueAuthHref from 'vue-auth-href'

Vue.config.productionTip = false
setInteractionMode('eager')

/*-- Custom field --*/
Vue.component('text-input', TextInput)
Vue.component('select-input', SelectInput)
Vue.component('combo-input', ComboInput)
Vue.component('textarea-input', TextareaInput)
Vue.component('textarea-editor', TextareaEditor)
Vue.component('doc-date', DocDate)
Vue.component('limited-char', LimitedChar)
Vue.component('doc-address-dr', DocAddressDr)
Vue.component('editable-preview', EditablePreview)
Vue.component('date-range', DateRange)
Vue.component('autocomplete', AutoComplete)

const authService = AuthService.getInstance()

http.interceptors.request.use(
  async config => {
    const token = await authService.getAuthorizationHeaderValue()
    if (token) {
      config.headers.common.Authorization = token
    }
    authService.pushLoadingState(true)
    return config
  },
  error => {
    authService.pushLoadingState(false)
    return Promise.reject(error)
  }
)

fHttp.interceptors.request.use(
  async config => {
    const token = await authService.getAuthorizationHeaderValue()
    if (token) {
      config.headers.common.Authorization = token
    }
    authService.pushLoadingState(true)
    return config
  },
  error => {
    authService.pushLoadingState(false)
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    authService.pushLoadingState(false)
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response?.status) {
      if (!Commons.IsProduction()) {
        // Don't alert in production
        switch (error.response.status) {
          case 404:
            alert('page not exist')
            break
          case 502:
            alert('page 502')
        }
      }
      return Promise.reject(error.response)
    }
    else {
      if (!Commons.IsProduction()) {
        // Don't alert in production
        console.log(JSON.stringify(error))
        alert(error.message)
      }
      return Promise.reject(error)
    }
  }
)
fHttp.interceptors.response.use(
  response => {
    authService.pushLoadingState(false)
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    return Promise.reject(error.response)
  }
)

Vue.directive('mask', VueMaskDirective)
Vue.filter('date-fr', (date: string) => {
  return Commons.TransformDateFormat(date)
})
Vue.filter('date-month-year', (date: string) => {
  return Commons.TransformDateFormatMonthYear(date)
})
Vue.filter('ago', (date: string) => {
  return Commons.RelativeTime(date)
})
Vue.filter('time', (timeSpan: string) => {
  return Commons.TransformTimeFormat(timeSpan, timeSpan)
})
Vue.filter('date-time-fr', (date: any) => {
  let theDate: Date|null = null
  if (date instanceof Date) {
    theDate = date
  } else if (typeof (date) === 'string') {
    theDate = new Date(date)
    if (isNaN(theDate.getTime())) {
      theDate = null
    }
  }
  if (theDate) {
    const options = {
      year: 'numeric',
      month: 'long'
    }
    return `Le ${ theDate.getDate() + (theDate.getDate() === 1 ? 'er' : '') + ' ' + theDate.toLocaleString('fr-CH', options) } à ${ theDate.getHours() }h${ Commons.padWithZeros(theDate.getMinutes(), 2) }`  
  }
  return date
})

Vue.filter('date-time', (date: any) => {
  return Commons.TransformDateTimeFormat(date)
})

Vue.filter('value-or-empty', (v: any) => {
  return Commons.ValueOrEmpty(v)
})

const options = {
  token: () => authService.getAccessToken(),
  removeDelay: 100
}
Vue.use(VueAuthHref, options)
VueAuthHref.install(Vue, options)

Vue.prototype.$sanitize = Commons.sanitize
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

const _currentDialog : any = {}
document.addEventListener("mousedown", e => {
  if (e.target) {
    const target = e.target as Element
    const closestDialog = target.closest(".v-dialog.v-dialog--active")
    if (e.button === 0 && closestDialog != null && target.classList.contains("v-card__title")) { // element which can be used to move element
      _currentDialog.el = closestDialog // element which should be moved
      _currentDialog.mouseStartX = e.clientX
      _currentDialog.mouseStartY = e.clientY
      _currentDialog.elStartX = _currentDialog.el.getBoundingClientRect().left
      _currentDialog.elStartY = _currentDialog.el.getBoundingClientRect().top
      _currentDialog.el.style.position = "fixed"
      _currentDialog.el.style.margin = 0
      _currentDialog.oldTransition = _currentDialog.el.style.transition
      _currentDialog.el.style.transition = "none"
    }
  }
})

document.addEventListener("mousemove", e => {
    if (_currentDialog.el === undefined) return
    _currentDialog.el.style.left = Math.min(
      Math.max(_currentDialog.elStartX + e.clientX - _currentDialog.mouseStartX, 0),
      window.innerWidth - _currentDialog.el.getBoundingClientRect().width
    ) + "px"
    _currentDialog.el.style.top = Math.min(
        Math.max(_currentDialog.elStartY + e.clientY - _currentDialog.mouseStartY, 0),
        window.innerHeight - _currentDialog.el.getBoundingClientRect().height
    ) + "px"
})

document.addEventListener("mouseup", () => {
    if (_currentDialog.el === undefined) return
    _currentDialog.el.style.transition = _currentDialog.oldTransition
    _currentDialog.el = undefined
})

setInterval(() => { // prevent out of bounds
    const dialog = document.querySelector(".v-dialog.v-dialog--active") as HTMLElement
    if (dialog === null) return
    dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px"
    dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px"
}, 100)