

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class DocDate extends Vue {
  @Prop({ type: Date, required: false, default: () => { return new Date() } })
  public date!: Date

  public customText = ''

  public options = {
    year: 'numeric',
    month: 'long'
  }

  public mounted () {
    this.customText = `Le ${this.date.getDate()}${(this.date.getDate() === 1 ? 'er' : '')} ${this.date.toLocaleString('fr-CH', this.options as Intl.DateTimeFormatOptions)}`
  }
}
