/* eslint-disable */
import Vue from 'vue'
import { extend } from 'vee-validate'
import { required, email, max, min, between, alpha_num, digits } from 'vee-validate/dist/rules'
import { VCol, VBtn, VDataTable, VCombobox, VTextarea, VAutocomplete, VTextField, VSelect, VFileInput, VChip } from 'vuetify/lib'
import Commons from './Helpers/commons'

extend('required', {
  ...required,
  message: '{_field_} est manquant'
})

extend('min', {
  ...min,
  message: '{_field_} ne peut pas être inférieur à {length} caractères'
})

extend('max', {
  ...max,
  message: '{_field_} ne peut être supérieure à {length} caractères'
})

extend('between', {
	...between,
	params: ['min', 'max'],
	validate(value, args: any) {
		return (args.min === '-' ? value >= 0 : value >= args.min) && value < 2147483647
	},
	message(field, args) {
		if (args.min.toString() === '-') {
			return field.concat(' ne peut être négatif ni excéder la valeur de ', args.max.toString())
		}
		return field.concat(' doit être compris entre ', args.min.toString(), ' et ', args.max.toString())
	}
})

extend('email', {
  ...email,
  message: `L'email doit être valide`
})

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} must contain alpha_num only'
})

extend('digits', {
  ...digits,
  message: '{_field_} doit être numérique avec {length} caractères'
})

extend('verify_password', {
  params: ['target'],
  validate (value, args: any) {
    return value === args.target
  },
  message: 'La confirmation du mot de passe ne correspond pas'
})

extend('min_date', {
  params: ['target'],
  validate (value, args: any) {
    const dateEnd = new Date(value)
    const dateStart = new Date(args.target)
    return (dateEnd >= dateStart)
  },
  message: 'La date de fin ne peut être inférieure à la date de début'
})

extend('min_time', {
  params: ['target'],
  validate (value, args: any) {
    const dateEnd = new Date(`1990-01-01T${value ?? ''}`)
    const dateStart = new Date(`1990-01-01T${args.target ?? ''}`)
    return (dateEnd >= dateStart)
  },
  message: 'Heure de fin ne peut être inférieure à l\'heure de début'
})

extend('password', {
  validate (value) {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
    return strongRegex.test(value)
  },  
  message: 'Le mot de passe doit contenir au moins : 1 caractère majuscule, 1 caractère minuscule, 1 caractère non alphanumérique 1 caractère et un caractère spécial (par exemple , . _ & ? @ )'
})

extend('phone', {
  validate (value) {
    const phoneRegex = new RegExp(/^((0041|0)|\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}$/)
    return phoneRegex.test(value)
  },
  message: 'Le numéro de téléphone doit être valide'
})

extend('fivemultiple', {
  validate (value) {
    return value % 5 === 0
  },
  message: 'La durée doit être un multiple de 5'
})

extend('positive', (value) => {
  if (!value || value >= 0) {
    return true;
  }

  return 'Le nombre entré doit être positif'
})

extend('positivefivemultiple', (value) => {
  if (!value || (value >= 0 && value % 5 === 0)) {
    return true;
  }

  return 'Le nombre entré doit être positif et un multiple de 5'
})

extend('positivemultiple', {
  params: ['multiple'],
  validate (value, args: any) {
    if (!value || (value >= 0 && value % Number(args.multiple) === 0)) {
      return true;
    }
    return false;
  },
  message (field, args: any) {
    return `Le nombre entré doit être positif et un multiple de ${args.multiple}`
  }
})

extend('greaterThanZeroMustBeSet', {
  params: ['message'],
  validate (value) {
    const n = Number(value)
    return !isNaN(n) && n > 0
  },
  message (field, args) {
    if (args.message) {
      return args.message
    }
    return 'Une valeur doit être sélectionnée'
  }
})

extend('hasPropertyMustBeSet', {
  params: ['target'],
  validate (value, args: any) {
    return value.hasOwnProperty(args.target) && !!value[args.target]
  },
  message: 'Une valeur doit être sélectionnée'
})

extend('dateRangeFromRequired', {
  validate (value) {
    return !!value
  },
  message: 'La date de début doit être sélectionnée.'
})

extend('dateRangeToRequired', {
  validate (value) {
    return !!value
  },
  message: 'La date de fin doit être sélectionnée.'
})

extend('min_date_target', {
  params: ['target'],
  validate (value, args: any) {
    const dateEnd = new Date(value)
    const dateStart = new Date(args.target)
    return (dateEnd >= dateStart)
  },
  message (field, args) {
    return `La date de début ne peut être inférieure à ${Commons.TransformDateFormat(args.target)}`
  },
})

extend('max_date_target', {
  params: ['target'],
  validate (value, args: any) {
    const dateEnd = new Date(args.target)
    const dateStart = new Date(value)
    return (dateStart <= dateEnd)
  },
  message (field, args) {
    return `La date de fin ne peut être supérieure à ${Commons.TransformDateFormat(args.target)}`
  },
})

extend('valid_enum', {
  params: ['enumList'],
  validate (value, args: any) {
    const parsedEnumList = JSON.parse(args.enumList)
    return !!parsedEnumList.find(o => o.value === value)
  },
  message (field, args) {
    return `La valeur choisie ne se trouve pas dans la liste des valeurs possibles.`
  },
})

extend('valid_nurse', {
  params: ['reason', 'nurseList'],
  validate (value, args: any) {
    const parsedNurseList = JSON.parse(args.nurseList)
    return !!parsedNurseList.find(n => n.id.toLowerCase() === value.toLowerCase())
  },
  message (field, args) {
    return `L'intervenant choisi ne peut pas être sélectionné car ${args.reason}.`
  },
})

extend('maxInt', {
  params: ['max'],
  validate (value, args: any) {
    const n = Number(value)
    return !isNaN(n) && n < Number(args.max)
  },
  message (field, args) {
    return `Veuillez saisir un nombre inférieur à ${args.max}`
  }
})

extend('maxOrEqualInt', {
  params: ['max'],
  validate (value, args: any) {
    const n = Number(value)
    return !isNaN(n) && n <= Number(args.max)
  },
  message (field, args) {
    return `Veuillez saisir un nombre inférieur ou égal à ${args.max}`
  }
})

/* -- Custom Text field -- */
Vue.component('CText', {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  }
})

/* -- Custom Textarea -- */
Vue.component('CTextarea', {
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    autoGrow: {
      type: Boolean,
      default: true
    },
    rows: {
      type: Number,
      default: 3
    }
  }
})

/*-- Custom Select --*/
Vue.component('CSelect', {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    menuProps: {
      type: Object,
      default: function() {
        return {
          offsetY: true
        }
      }
    }
  }
})

/*-- Custom File Input --*/
Vue.component('CFileInput', {
  extends: VFileInput,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  }
})

/* -- Custom Combobox -- */
Vue.component('CCombobox', {
  extends: VCombobox,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    menuProps: {
      type: Object,
      default: function() {
        return {
          offsetY: true
        }
      }
    }
  }
})

/* -- Custom autocomplete -- */
Vue.component('CAutocomplete', {
  extends: VAutocomplete,
  props: {
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: true },
    clearable: {type: Boolean, default:true}
  }
})

/* -- Custom Btn -- */
Vue.component('CBtn', {
  extends: VBtn,
  props: {
    small: {
      type: Boolean,
      default: true
    },
    elevation: {
      type: Number,
      default: 0
    }
  }
})

/* -- Custom Column -- */
Vue.component('CCol', {
  extends: VCol,
  props: {
    cols: {
      type: Number,
      default: 12
    },
    sm: {
      type: Number,
      default: 6
    },
    md: {
      type: Number,
      default: 4
    }
  }
})

/*-- Custom Data Table --*/
Vue.component('CDataTable', {
  extends: VDataTable,
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    },
    dense: {
      type: Boolean,
      default: true
    },
    mobileBreakpoint: {
      type: Number,
      default: 1000
    },
    headerProps: {
      type: Object,
      default: function() {
        return {
          sortIcon: 'mdi-chevron-down'
        }
      }
    }
  }
})

/* -- Custom Chips -- */
Vue.component('CChip', {
  extends: VChip,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    ripple: {
      type: Boolean,
      default: false
    }
  }
})
