



























import { ValidationProvider } from 'vee-validate'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    ValidationProvider
  }
})
export default class ComboInput extends Vue {
  @Prop({ default: '' }) public value!: string
  @Prop({ default: '' }) public rules!: string
  @Prop({ default: '' }) public name!: string
  @Prop({ default: '' }) public label!: string
  @Prop({ default: undefined }) public vid!: string | undefined
  @Prop({ default: [] }) public items!: string[] | any[]
  @Prop({ default: false }) public multiple!: boolean
  @Prop({ default: false }) public readonly!: boolean
  @Prop({ default: false }) public disabled!: boolean
  @Prop({ default: 'text' }) itemText!: string
  @Prop({ default: 'value' }) itemValue!: string

  @Watch('innerValue') onChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value') onValueChanges (val: string) {
    if (val !== this.innerValue) {
      this.innerValue = val
    }
  }

  public innerValue = ''

  public get hasValue () {
    return !!this.innerValue
  }

  public get requiredClass () {
    return this.rules?.includes('required') ? 'required' : ''
  }

  public emitAppendClick () {
    this.$emit('appendiconClick')
  }

  public mounted () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
