import { ConsultationModel } from '@/models/consultation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { PatientService } from '@/services/patient-service'
import { Subscription } from 'rxjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { consultationGenre, consultationGenrePediatrie, consultationTypes, PrestationDiabetique, PrestationDietetique } from '../../Dossier/Constants'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import EvaluationInitiale from '@/views/Patients/Overview/EvaluationInitiale/EvaluationInitiale.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import Commons from '@/components/shared/Helpers/commons'
import AppointmentSelector from '@/components/shared/AppointmentSelector/AppointmentSelector.vue'
import { AgendaService } from '@/services/agenda-service'
import { AppointmentForConsultationResponseModel } from '@/models/agenda-model'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    Alert,
    AppointmentSelector,
    Confirm,
    EvaluationInitiale,
    ValidationObserver,
    ValidationProvider
  }
})
export default class Consultation extends Vue {
  private subscriptions: Subscription[] = []
  private selectedDossier!: dossierPatientModel
  private patientService = PatientService.getInstance()
  public consultation: ConsultationModel = {}
  private agendaService = AgendaService.getInstance()

  @Prop()
  public mode!: string

  @Prop()
  public count!: string

  public typeConsultation = consultationTypes
  public genreConsultation = consultationGenre
  public genreConsultationPediatrie = consultationGenrePediatrie
  public today = Commons.GetTodayFormatted()
  public isLoading = false
  public errorMessages: string[] = []
  public appointments: AppointmentForConsultationResponseModel[] = []
  private readyAppointments = false
  public type = ''

  public isPediatrie = false

  public showConfirm = false
  public routeNextCallback = null as any
  public redirectPath = null as string | null
  public hasSaved = false

  beforeRouteLeave (to, from, next) {
    if (!this.hasSaved && !this.isReadOnly) {
      if (this.redirectPath == null) {
        this.redirectPath = to
      }
      this.showConfirm = true
      this.routeNextCallback = next
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    if (!this.isReadOnly) {
      e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    }
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.routeNextCallback) {
      this.routeNextCallback()
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public get isReadOnly () {
    return this.consultation.isLinkedToInvoice
  }

  public mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    this.readyAppointments = false
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe((dossier: dossierPatientModel) => {
      this.selectedDossier = dossier
    }))
    if (this.mode) {
      this.isPediatrie = this.mode === '4'
      this.consultation = {
        date: this.today,
        type: parseInt(this.mode),
        title: '',
        evaluationInitialItems: []
      }
      this.getAllRelevantAppointments()
    } else {
      this.subscriptions.push(this.patientService.consultationSelected$.subscribe((consultation: ConsultationModel) => {
        this.consultation = consultation
        this.isPediatrie = this.consultation.type === 4
        this.getAllRelevantAppointments()
      }))
    }
    if (!this.selectedDossier?.guid || !(parseInt(this.mode) || this.consultation.type as number)) {
      this.$router.push('/patient')
    }
    this.ensureUserNavTitle()
  }

  public get getInfoText () {
    if (this.mode) {
      return "Une saisie d'heures sera automatiquement créée pour cette consultation."
    }
    return "La saisie d'heures existante sera automatiquement adaptée pour cette consultation."
  }

  private getAllRelevantAppointments () {
    this.agendaService.getAppointmentsForConsultation(this.selectedDossier?.guid, this.consultation.type!, this.consultation.id).then((appointments) => {
      this.appointments = appointments
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyAppointments = true
    })
  }

  get isDietOrDiab () {
    const val = [PrestationDietetique, PrestationDiabetique].includes(parseInt(this.mode) || this.consultation.type as number)
    if (val) this.consultation.evaluationInitialItems = []
    return val
  }

  public ensureUserNavTitle () {
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
    }
  }

  public async Save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isLoading = true
      const res = await this.patientService.AddDossierConsultations(this.selectedDossier.guid, this.consultation)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isLoading = false
        })
      if ((res as AxiosResponse<any>)?.status === 200) {
        this.hasSaved = true
        this.redirectPath = null
        this.patientService.getDossierConsultations(this.selectedDossier.guid)
        this.returnToList()
      }
    } else {
      let merged = this.$refs
      if (this.$refs.evaluationInitialeRef) {
        merged = Object.assign(this.$refs, (this.$refs.evaluationInitialeRef as Vue).$refs)
      }
      merged = Object.assign(merged, (this.$refs.rdvConcerné as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public returnToList (forceReset = false) {
    if (forceReset) {
      this.redirectPath = null
    }
    if (this.redirectPath == null) {
      this.$router.go(-1)
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  public buttonBack () {
    this.redirectPath = null
    // act as if we saved, so that we bypass the confirm dialog
    this.hasSaved = true
    this.returnToList()
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
  }

  public get getObjectifText () {
    if (this.mode === '2' || this.consultation.type === 2) {
      return "Objectif nutritionnel"
    }
    return "Objectif infirmier"
  }
}
