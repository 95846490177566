










































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
  @Prop() public errorMessages!: string[]
  @Prop() public value!: boolean
  @Prop({ default: false }) public canClose!: boolean

  public emitClose () {
    this.$emit('close')
  }

  public defaultIcon () {
    if (this.$attrs.icon) {
      return this.$attrs.icon
    } else if (this.$attrs.type === 'error') {
      return 'mdi-bell'
    } else if (this.$attrs.type === 'success') {
      return 'mdi-check'
    } else if (this.$attrs.type === 'warning') {
      return 'mdi-clipboard-alert-outline'
    } else {
      return false
    }
  }

  public defaultColor () {
    if (this.$attrs.color) {
      return this.$attrs.color
    } else if (this.$attrs.type === 'error') {
      return 'red'
    } else if (this.$attrs.type === 'success') {
      return 'green'
    } else if (this.$attrs.type === 'warning') {
      return 'orange'
    } else {
      return false
    }
  }
}
