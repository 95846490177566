

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})
export default class LimitedChar extends Vue {
  @Prop({ default: '' }) public text!: string
  @Prop({ default: 50 }) public limit!: number
  @Prop({ default: true }) public tooltip!: boolean
  @Prop({ default: 500 }) public tooltipMaxWidth!: number
}
