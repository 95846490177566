















import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  History
} from 'tiptap-vuetify'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    TiptapVuetify
  }
})
export default class TextareaEditor extends Vue {
  @Prop({ default: '' }) public value!: string
  @Prop({ default: '' }) public id!: string

  public isFocus = false

  public onFocus () {
    this.isFocus = true
  }

  public onBlur (e) {
    if (!(e.relatedTarget?.classList && Array.from(e.relatedTarget.classList).some(x => (x as string).indexOf('tiptap-vuetify-editor') >= 0))) {
      this.isFocus = false
    }
  }

  @Watch('content') onChange (value: string) {
    this.$emit('input', value)
  }

  @Watch('value') onValueChanges (val: string) {
    if (val !== this.content) {
      this.content = val
    }
  }

  public extensions = [
    History,
    Underline,
    Strike,
    Italic,
    Bold,
    ListItem,
    BulletList,
    OrderedList,
    Link,
    HardBreak
  ]

  public content = ''

  public mounted () {
    if (this.value) {
      this.content = this.value
    }
  }
}
