var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_vm._l((_vm.reseaux),function(reseau,idx){return _c('div',{key:idx,staticClass:"subsection"},[_c('h4',{staticClass:"subtitle"},[_vm._v(_vm._s(reseau.title))]),_c('c-btn',{staticClass:"btn-red",on:{"click":function($event){$event.stopPropagation();return _vm.openReseauDialog(reseau.mode)}}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Ajouter une nouvelle entrée")])],1),[_c('c-data-table',{attrs:{"headers":reseau.headers,"items":reseau.data,"sort-by":['relationship', 'reseauMedicalExtra.type', 'nom'],"hide-default-footer":reseau.data ? reseau.data.length <= 10 : true,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll }},scopedSlots:_vm._u([{key:"item.reseauMedicalExtra.isActive",fn:function(ref){
var item = ref.item;
return [(item.reseauMedicalExtra.isActive)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-minus-circle-outline ")])]}},{key:"item.isEmergencyContact",fn:function(ref){
var item = ref.item;
return [(item.isEmergencyContact)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):_vm._e(),(item.isEmergencyContact === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-minus-circle-outline ")]):_vm._e(),_vm._v(" "+_vm._s(item.isEmergencyContact ? 'Oui' : 'Non')+" ")]}},{key:"item.reseauMedicalExtra.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertTypeIdtoName(item.reseauMedicalExtra))+" ")]}},{key:"item.nom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-min-8 w-max-20"},[_vm._v(" "+_vm._s(_vm.getFullName(item))+" ")])]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-min-8"},[_vm._v(" "+_vm._s(item.telephone)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editItem(item, reseau.mode)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}}],null,true)})]],2)}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.Close()}}},[_c('span',[_vm._v(" Retour à la liste des patients ")])])],1),_c('reseau-dialog',{attrs:{"visible":_vm.dialog,"title":_vm.dialogTitle,"editedItem":_vm.editedItem,"isEdit":_vm.isEdit,"isLoading":_vm.isLoading,"searchLoading":_vm.searchLoading,"errorMessages":_vm.errorMessages,"professionalsList":_vm.professionalsList,"mode":_vm.mode},on:{"professional-select":_vm.professionalSelected,"close":function($event){return _vm.closeReseauDialog()},"reset-edit":function($event){return _vm.resetEditingItem()},"save":function($event){return _vm.save()}}}),_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":("Supprimer " + (_vm.editedItem.nom) + " " + (_vm.editedItem.prenom)),"message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer ces données ?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }