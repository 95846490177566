import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { PatientService } from '@/services/patient-service'
import { Subscription } from 'rxjs'
import { DossierAssurance } from '../../Static'
import { AssuranceHistoryRequest, AssuranceHistoryResponse } from '@/models/insurances-model'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'
import { ErrorService } from '@/services/error.service'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Alert from '@/components/shared/Alert.vue'
import InsuranceHistoryDialog from '@/components/shared/Dialogs/InsuranceHistoryDialog/InsuranceHistoryDialog.vue'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    Alert,
    Confirm,
    InsuranceHistoryDialog,
    ValidationObserver
  }
})
export default class InfoAssurance extends Vue {
  @Prop() public dossierAssurance!: DossierAssurance
  @Prop() public dossierId!: string

  private patientService = PatientService.getInstance()
  public assurances: any[] = []
  public subscription!: Subscription
  public assuranceHistory: AssuranceHistoryResponse[] = []
  public isHistoryLoading = false
  public historyHeaders: any[] = []
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public errorMessages: string[] = []
  public editedItem: AssuranceHistoryResponse = InfoAssurance.defaultInsuranceHistoryModel()
  public editDialog = false
  public showDeleteConfirm = false
  public isDialogBusy = false

  private static defaultInsuranceHistoryModel (): AssuranceHistoryResponse {
    return {
      id: 0,
      assuranceId: 0,
      assuranceAssuranceName: '',
      dossierPatientId: '',
      isCurrent: false
    }
  }

  public mounted () {
    this.subscription = this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) this.patientService.getAllAssurance()
    })

    this.historyHeaders = [
      {
        text: 'Assurance',
        value: 'assuranceAssuranceName',
        width: '40%',
        sortable: false
      },
      {
        text: 'Numéro d\'assuré',
        value: 'assuranceNumber',
        width: '25%',
        sortable: false
      },
      {
        text: 'Depuis',
        value: 'insuredFrom',
        width: '15%',
        sortable: false
      },
      {
        text: 'Jusqu\'à',
        value: 'insuredTo',
        width: '15%',
        sortable: false
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '5%'
      }
    ]
    this.getAssuranceHistory()
  }

  public getAssuranceHistory () {
    if (this.isHistoryLoading) {
      return
    }
    this.isHistoryLoading = true
    this.patientService.getAllAssuranceHistory(this.dossierId)
      .then(response => {
        this.assuranceHistory = response
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isHistoryLoading = false
      })
  }

  public destroyed () {
    this.subscription.unsubscribe()
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public openInsuranceDialog () {
    this.hideAlert()
    this.editedItem = InfoAssurance.defaultInsuranceHistoryModel()
    this.editDialog = true
  }

  public editItem (item: AssuranceHistoryResponse) {
    this.editedItem = item
    this.editDialog = true
  }

  public displayConfirmDialog (item: AssuranceHistoryResponse) {
    this.editedItem = item
    this.showDeleteConfirm = true
  }

  public async confirmCallback (value: boolean) {
    this.showDeleteConfirm = false
    if (value) {
      this.isDialogBusy = true
      await this.patientService.deleteInsuranceHistory(this.dossierId, this.editedItem.id)
        .then(async () => {
          this.hideAlert()
          this.getAssuranceHistory()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        }).finally(() => {
          this.isDialogBusy = false
          this.editedItem = InfoAssurance.defaultInsuranceHistoryModel()
        })
    }
  }

  public closeInsuranceHistoryDialog () {
    this.isDialogBusy = false
    this.editedItem = InfoAssurance.defaultInsuranceHistoryModel()
    this.hideAlert()
    this.editDialog = false
    this.getAssuranceHistory()
  }

  public async saveInsuranceHistory () {
    this.hideAlert()
    this.isDialogBusy = true
    await this.patientService.addUpdateInsuranceHistory(this.dossierId, this.editedItem as AssuranceHistoryRequest)
      .then(async () => {
        this.editDialog = false
        this.getAssuranceHistory()
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isDialogBusy = false
      })
  }

  public isCurrentInsurance (insurance: AssuranceHistoryResponse) {
    return insurance?.isCurrent ?? false
  }
}
