





















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class TableCellInput extends Vue {
  @Prop({ default: '' }) public rules!: string
  @PropSync('input', { type: String, default: '' })
  public syncedvalue!: string

  @Prop({ default: '' }) public suffix!: string
  @Prop({ default: '' }) public id!: string

  public emitCell () {
    this.resetField()
    this.$emit('cell-change')
  }

  public onBlur () {
    this.resetField()
  }

  private resetField () {
    // Numeric fields have a value of empty if you enter something like "-" or "-1-" in them which is invalid as a number
    // but this input remains visible in the field, but since validation sees "empty" (because calling value on a field with such an input
    // returns empty) then it will go through with an empty value but visually it looks like you're sending this empty value
    const element = (document.getElementById(this.id)! as HTMLInputElement)
    if (this.syncedvalue.length === 0) {
      this.syncedvalue = ''
      element.value = ''
    }
  }
}
