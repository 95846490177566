import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'

@Component({
  components: {
    Alert
  }
})
export default class Confirm extends Vue {
  @Prop()
  public visible!: boolean

  @Prop()
  public message!: string

  @Prop({ default: 'Confirmer' })
  public title!: string

  @Prop({ default: false })
  public yesLoading!: boolean

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public errorMessages!: string[]

  @Prop({ default: 'btn-white' })
  public noStyle!: string

  @Prop({ default: 'btn-red' })
  public yesStyle!: string

  @Prop({ default: '390' })
  public maxWidth!: string

  get show () {
    return this.visible
  }

  public closeDialog (value: boolean) {
    this.$emit('close', value)
  }
}
