import { RoleGroup } from "./role-enums"

export const defaultItemsPerPageWithoutAll = [
  10, 50, 100
]

export const defaultItemsPerPageWithAll = [
  10, 50, 100, -1
]

export const defaultItemsPerPage = 50

export const RoleGroups = [
  { text: 'Administration', value: '' + RoleGroup.Administration },
  { text: 'Gestion', value: '' + RoleGroup.Gestion },
  { text: 'Cliniciennes', value: '' + RoleGroup.Cliniciennes },
  { text: 'Infirmières', value: '' + RoleGroup.Infirmières },
  { text: 'Diététitiennes', value: '' + RoleGroup.Diététitiennes },
  { text: 'DirectionLDS', value: '' + RoleGroup.DirectionLDS },
  { text: 'ComptaLDS', value: '' + RoleGroup.ComptaLDS },
  { text: 'Informatique', value: '' + RoleGroup.Informatique }
]

export const accessDeniedError = { errors: ['Vous n\'avez pas le droit d\'accéder à cette page.'], title: 'Accès interdit' }

export enum AssuranceInvoiceMode
{
    TarifSuisse = 1,
    HKS = 2,
    SUVA = 3,
}

export const AssuranceInvoiceModeFilter = [
  { value: AssuranceInvoiceMode.TarifSuisse, text: 'tarifsuisse' },
  { value: AssuranceInvoiceMode.HKS, text: 'HKS' },
  { value: AssuranceInvoiceMode.SUVA, text: 'SUVA' }
]
