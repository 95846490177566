import http from '@/http-client'
import { BehaviorSubject } from 'rxjs'
import { AgendaEventResponseModel, AppointmentResponseModel, AppointmentReqModel, AppointmentForConsultationResponseModel, CancelAppointmentModel, MoveAppointmentModel, AppointmentHistoryResponseModel, AppointmentUpsetResponseModel } from '@/models/agenda-model'

export class AgendaService {
  private static instance: AgendaService
  private _appointmentSelectedSource = new BehaviorSubject<AppointmentResponseModel>(
    {
      id: 0,
      dossierId: '',
      nurseId: '',
      prestationId: '',
      roomId: 0,
      statusId: 0,
      appointmentTypeId: 0,
      consultationTypeId: 0,
      importantInfo: '',
      description: '',
      otherLocation: '',
      start: '',
      end: '',
      cancelReason: ''
    }
  )

  public appointmentSelected$ = this._appointmentSelectedSource.asObservable()

  public static getInstance (): AgendaService {
    if (!AgendaService.instance) {
      AgendaService.instance = new AgendaService()
    }
    return AgendaService.instance
  }

  // Appointments
  public async getAppointmentsInRange (from: string, to: string, movingEventId?: number) {
    const response = await http.get(`/agenda?from=${from}&to=${to}&movingEventId=${movingEventId ?? ''}`)
    return (response.data) as AgendaEventResponseModel[]
  }

  public async getAppointmentDetail (appointmentId: number) {
    const response = await http.get(`/agenda/${appointmentId}`)
    return (response.data) as AppointmentResponseModel
  }

  public async getAppointmentsForConsultation (patientId: string, consultationTypeId: number, consultationId?: string) {
    const response = await http.get(`/agenda/patient/${patientId}/${consultationTypeId}/${consultationId ?? ''}`)
    return (response.data) as AppointmentForConsultationResponseModel[]
  }

  public async getAppointmentsForPediatriqueEvaluation (patientId: string, evaluationId?: string) {
    const response = await http.get(`/agenda/evaluation/patient/${patientId}/${evaluationId ?? ''}`)
    return (response.data) as AppointmentForConsultationResponseModel[]
  }

  public async addUpdateAppointment (appointment: AppointmentReqModel) {
    const response = await http.post('/agenda', appointment)
    return response.data as AppointmentUpsetResponseModel
  }

  public async deleteAppointment (id?: number) {
    if (!id) return 0
    const response = await http.delete(`/agenda/${id}`)
    return response.data as number
  }

  public async cancelAppointment (data: CancelAppointmentModel) {
    if (!data.id) return 0
    const response = await http.put(`/agenda/cancel`, data)
    return response.data as number
  }

  public async moveAppointment (data: MoveAppointmentModel) {
    if (!data.id) return 0
    const response = await http.put(`/agenda/move`, data)
    return response.data as number
  }

  public async getPatientAppointments (patientId: string) {
    const response = await http.get(`/agenda/rdv/${patientId}`)
    return (response.data) as AppointmentResponseModel[]
  }

  public updateAppointmentSelected (appointment: AppointmentResponseModel) {
    this._appointmentSelectedSource.next(appointment)
  }

  // History
  public async getAppointmentHistory (appointmentId: number) {
    const response = await http.get(`/agenda/history/${appointmentId}`)
    return (response.data) as AppointmentHistoryResponseModel[]
  }

  public async getAppointmentDoctor (appointmentId: number) {
    const response = await http.get(`/agenda/doctor/${appointmentId}`)
    return (response.data) as any
  }
}
