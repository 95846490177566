































import { AuthService } from '@/services/auth-service'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'
import { Component, Vue } from 'vue-property-decorator'
import { DrawerItem } from '@/components/shared/Models/drawer-model'

@Component({
  components: {
  }
})
export default class AppBar extends Vue {
  public mounted () {
    AuthService.getInstance().isAuth$.subscribe(auth => {
      this.refreshNavItems()
    })
  }

  public refreshNavItems () {
    if (ModuleAuthorisationManager.HasAccess('timbreuse')) {
      this.items = [...this.allItems]
    } else {
      this.items = [...this.itemsNoTimbreuse]
    }
  }

  private items: DrawerItem[] = []

  private allItems = [
    {
      title: 'Patients',
      icon: 'mdi-account-circle-outline',
      to: '/patient'
    },
    {
      title: 'Agenda',
      icon: 'mdi-calendar-month-outline',
      to: '/agenda'
    },
    {
      title: 'Prescriptions',
      icon: 'mdi-comment-text-outline',
      to: '/prescriptions'
    },
    {
      title: 'Facturation',
      icon: 'mdi-file-table-outline',
      to: '/facturation'
    },
    {
      title: 'Heures',
      icon: 'mdi-clock-outline',
      to: '/timbreuse'
    },
    {
      title: 'Administration',
      icon: 'mdi-folder-open-outline',
      to: '/administration'
    }
  ]

  private itemsNoTimbreuse = [
    {
      title: 'Patients',
      icon: 'mdi-account-circle-outline',
      to: '/patient'
    },
    {
      title: 'Agenda',
      icon: 'mdi-calendar-month-outline',
      to: '/agenda'
    },
    {
      title: 'Prescriptions',
      icon: 'mdi-comment-text-outline',
      to: '/prescriptions'
    },
    {
      title: 'Facturation',
      icon: 'mdi-file-table-outline',
      to: '/facturation'
    },
    {
      title: 'Administration',
      icon: 'mdi-folder-open-outline',
      to: '/administration'
    }
  ]
}
