import { dossierPatientModel } from '@/models/dossier-response-model'
import { LayoutService } from '@/services/layout-service'
import { DossierNavItems, DossierNavItemsNoCreate, FullNavItems, FullNavItemsNoCreate, FullNavItemsNoCreateNoOverview, FullNavItemsNoOverview } from '@/views/Patients/Static'
import sanitizeHTML from 'sanitize-html'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import VueRouter from 'vue-router'
import { AppUser } from '@/models/app-user-dto'
import { INestedTableObject } from '@/models/common-models'
import { PrestationModel, PrestationsToRenewResponseModel } from '@/models/prescriptions-model'
import { ValidationObserver } from 'vee-validate'
import { RenewablePrestationCategeoryTypeIds } from '@/models/static'
import Vue from '*.vue'
import AgendaHelpers from './agenda-helpers'
import { AxiosResponse } from 'axios'
import { DataOptions } from 'vuetify/types'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

export default class Commons {
  private static layoutService = LayoutService.getInstance()
  private static nowDateParts = new Date().toISOString().substring(0, 10).split('-')

  public static CreateDefaultDataOptions (): DataOptions {
    return {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 0,
      multiSort: false,
      mustSort: false,
      page: 0,
      sortBy: [],
      sortDesc: []
    }
  }

  public static PushPagingOptionsToQuerystring (queryString: string[], options: DataOptions) {
    queryString.push(`limit=${options.itemsPerPage}`)
    queryString.push(`offset=${options.page}`)
  }

  public static PushSortingOptionsToQuerystring (queryString: string[], options: DataOptions) {
    if (options.sortBy.length === options.sortDesc.length) {
      options.sortBy.forEach((e, i) => {
        let s = `orderBy=${e}`
        if (options.sortDesc[i] === true) {
          s += ' desc'
        }
        queryString.push(s)
      })
    }
  }

  public static PushOptionsToQuerystring (queryString: string[], options: DataOptions) {
    Commons.PushPagingOptionsToQuerystring(queryString, options)
    Commons.PushSortingOptionsToQuerystring(queryString, options)
  }

  public static FindNurse (allInfirmieres: AppUser[], nurseId: string) {
    return allInfirmieres.find(i => i.id.toLowerCase() === nurseId?.toLowerCase())
  }

  public static GetPatientFullname (allPatients: dossierPatientModel[], patientId: string) {
    return allPatients.find(p => p.guid.toLowerCase() === patientId.toLowerCase())?.fullName
  }

  public static IsProduction () {
    return process?.env?.NODE_ENV === "production"
  }

  public static TodayDate () {
    const d = new Date()
    return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
  }

  public static GetDate (isoDate: string) {
    if (!isoDate) {
      return undefined
    }
    let dateStr = isoDate
    if (isoDate.indexOf('T') > -1) {
      dateStr = isoDate.substr(0, isoDate.indexOf('T'))
    }
    const tokens = dateStr.split('-')
    if (tokens.length >= 3) {
      return new Date(Number(tokens[0]), Number(tokens[1]) - 1, Number(tokens[2]))
    }
    return undefined
  }

  public static GetTodayFormatted () {
    return this.FormatDateForInputField(new Date())
  }

  public static padWithZeros (value, length) {
    if (value.toString().length >= length) {
      return value
    }
    return (("0" + value).slice(-length))
  }

  public static FormatTimeForInputField (date) {
    let h: any = null
    let m: any = null
    if (date instanceof Date) {
      h = date.getHours()
      m = date.getMinutes()
    } else if (typeof (date) === 'string') {
      const split = date.split(':')
      if (split.length >= 2) {
        h = parseInt(split[0], 10)
        m = parseInt(split[1], 10)
      }
    }
    if (h !== null && m !== null) {
      h = Commons.padWithZeros(h, 2)
      m = Commons.padWithZeros(m, 2)
      return `${h}:${m}`
    }
    return ''
  }

  public static FormatDateForInputField (date: Date) {
    return Commons.AddMinutes(date, -date.getTimezoneOffset()).toISOString().substr(0, 10)
  }

  public static AddMinutes (date: Date, minutes: number) {
    return new Date(date.getTime() + minutes * 60000)
  }

  public static TransformDateFormat (date) {
    if (!date) return null
    let isoDate
    if (date instanceof Date) {
      isoDate = Commons.AddMinutes(date, -date.getTimezoneOffset()).toISOString()
    }
    if (typeof (date) === 'string') {
      isoDate = new Date(date.substr(0, 10)).toISOString()
    }
    if (isoDate) {
      const parts = isoDate.substr(0, 10)
      const [year, month, day] = parts.split('-')
      return `${day}.${month}.${year}`
    }
    return null
  }

  public static TransformTimeFormat (timeSpan, fallback) {
    const d = new Date(timeSpan)
    if (isNaN(d.getTime())) {
      const split = timeSpan.split(':')
      if (split.length >= 2) {
        return `${split[0]}:${Commons.padWithZeros(split[1], 2)}`
      }
    } else {
      return `${d.getHours()}:${Commons.padWithZeros(d.getMinutes(), 2)}`
    }
    return fallback
  }

  public static Yesterday () {
    const today = new Date()
    return new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 1)
  }

  public static RoundTimeTo (date: Date, roundToMinutes = 15) {
    const coeff = 1000 * 60 * roundToMinutes
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff)
    return `${rounded.getHours()}:${rounded.getMinutes()}`
  }

  public static TransformDateFormatMonthYear (date) {
    if (!date) return null
    let isoDate
    if (date instanceof Date) {
      isoDate = Commons.AddMinutes(date, -date.getTimezoneOffset()).toISOString()
    }
    if (typeof (date) === 'string') {
      isoDate = new Date(date.substr(0, 10)).toISOString()
    }
    if (isoDate) {
      const parts = isoDate.substr(0, 10)
      const [year, month, _] = parts.split('-')
      const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
      return `${months[parseInt(month, 10) - 1]} ${year}`
    }
    return null
  }

  public static ValueOrEmpty (value: any) {
    return value ?? ''
  }

  public static TransformDateTimeFormat (date) {
    if (!date) return null
    let isoDate
    if (date instanceof Date) {
      isoDate = date
    } else if (typeof (date) === 'string') {
      if (!date.endsWith('Z')) {
        date = `${date}Z`
      }
      isoDate = new Date(date)
    }
    if (!isNaN(isoDate.getTime())) {
      const isoDateStr = isoDate.toISOString()
      if (isoDateStr) {
        const parts = isoDateStr.substr(0, 10)
        const [year, month, day] = parts.split('-')
        const [hour, minutes] = isoDateStr.substr(11, 5).split(':')
        return `${day}.${month}.${year} ${hour}:${minutes}`
      }
    }
    return null
  }

  public static isDateActive (dateEnd: string) {
    if (dateEnd) {
      const [y, m, d] = dateEnd.split('-')
      const [nowYear, nowMonth, nowDay] = this.nowDateParts
      const now = Date.UTC(+nowYear, +nowMonth, +nowDay)
      const end = Date.UTC(+y, +m, +d)
      return now > end
    }
  }

  public static RelativeTime (date: string) {
    if (date) {
      let seconds = Math.floor((+new Date().getTime() - +new Date(date).getTime()) / 1000)
      const pre = seconds > 0 ? ' il y a ' : 'dans '
      seconds = Math.abs(seconds)

      if (!seconds) return ''
      const intervals = {
        an: 31536000,
        moi: 2592000,
        semaine: 604800,
        jour: 86400,
        heure: 3600,
        minute: 60
      }
      let counter = 0
      let message = ''
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i])
        if (counter > 0 && (intervals[i] >= intervals.heure || !message)) {
          if (counter === 1) {
            message = message.concat(` ${counter} ${i}${i === 'moi' ? 's' : ''}`)
          } else {
            message = message.concat(` ${counter} ${i}s`)
          }
          seconds = seconds - counter * intervals[i]
        }
      }
      return `${pre} ${message}`
    }
  }

  public static updateDossierName () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const dossier = JSON.parse(cachedDossier!) as dossierPatientModel
      let index = 2
      if (!ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
        index = 1
      }
      let navItems = FullNavItems
      if (ModuleAuthorisationManager.HasAccess('dossier.dossierPatientOverview')) {
        if (!ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
          navItems = FullNavItemsNoCreate
        }
      } else {
        if (ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
          navItems = FullNavItemsNoOverview
        } else {
          navItems = FullNavItemsNoCreateNoOverview
        }
      }
      navItems[index].title = dossier.fullName
      navItems[index].infoImportantCount = dossier.espaceItems?.length || 0
      navItems[index].appointmentsWithImportantInfoCount = dossier.warnings?.length || 0
      this.layoutService.updateDrawerList(navItems)
    }
  }

  public static isCachedDossierPatientValid (cachedDossier: string|null) {
    if (!!cachedDossier && cachedDossier !== '{}') {
      const selectedDossier = JSON.parse(cachedDossier) as dossierPatientModel
      return !!(selectedDossier?.guid)
    }
    return false
  }

  public static restNavDrawer () {
    if (ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
      this.layoutService.updateDrawerList(DossierNavItems)
    } else {
      this.layoutService.updateDrawerList(DossierNavItemsNoCreate)
    }
  }

  public static sanitize (html: string) {
    if (!html) return ''
    const htmlCRs = html.replaceAll(/\n/g, '<br />')
    return sanitizeHTML(htmlCRs)
  }

  public static async getDossierReseau (guid: string, errorMessages: string[], reseauPro: any[]) {
    if (guid) {
      const response: any = await PatientService.getInstance().getDossierReseauInfo(guid)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          Commons.pushErrorMessages(errorMessages, res)
        })

      if (response?.status === 200) {
        this.mapDossierReseau(response.data, reseauPro)
      }
    }
  }

  private static mapDossierReseau (dossierResponse: any, reseauPro: any[]) {
    reseauPro.length = 0
    dossierResponse.forEach((reseau: any) => {
      if (!!reseau.reseauMedicalExtra && reseau.reseauMedicalExtra.type && reseau.reseauMedicalExtra.isActive) {
        reseau.fullname = this.getFullName(reseau)
        reseauPro.push(reseau)
      }
    })
  }

  public static getFullName (item: {nom: string; prenom: string }) {
    return `${item?.nom} ${item?.prenom} ${this.getEmployerName(item)}`
  }

  private static getEmployerName (item: any) {
    return (!item.reseauMedicalExtra?.employeur
      ? '' : (item.nom as string).trim().length === 0
        ? `${item.reseauMedicalExtra?.employeur}` : ''
    )
  }

  public static newlines2br (text: string) {
    if (!text) {
      return text
    }
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
  }

  public static getDossierOrRedirect (router?: VueRouter, mandatory = true, redirectTo = '/patient') {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      const dossier = JSON.parse(cachedDossier) as dossierPatientModel
      if (router && mandatory && !dossier?.guid) {
        router.push(redirectTo)
      }
      return dossier
    }
    return null
  }

  public static setRowClasses (objects: INestedTableObject[]) {
    objects.forEach((row, id) => {
      row.rowClass = 'row-flip-' + (id % 2 ? 'even' : 'odd')
    })
  }

  public static canPrestationBeRenewed (prestation: PrestationModel) {
    return RenewablePrestationCategeoryTypeIds.includes(prestation.categeoryTypeId ?? 0)
  }

  public static canPrestationTRBeRenewed (prestation: PrestationsToRenewResponseModel) {
    return RenewablePrestationCategeoryTypeIds.includes(prestation.categeoryTypeId ?? 0)
  }

  private static findFirstInput (e) {
    const inputNames = ['input', 'textarea', 'select']
    if (!e) {
      return null
    }
    if (inputNames.includes(e.nodeName.toLowerCase())) {
      return e
    } else {
      if (e.childElementCount > 0) {
        for (const c of e.children) {
          const firstInput = this.findFirstInput(c)
          if (firstInput !== null) {
            return firstInput
          }
        }
      }
      return null
    }
  }

  private static tryToSelectFirstInput (e) {
    let el = (e as Vue).$el
    // it may be that ec is not a Vue but already an HTMLInputElement
    if (!el) {
      el = e
    }
    return this.findFirstInput(el)
  }

  public static focusFirstComponentWithError (observer: InstanceType<typeof ValidationObserver>, refs: any, refSuffix = '') {
    const errors = Object.entries(observer.errors).map(([key, value]) => ({ key, value })).filter(error => error.value.length)
    if (errors.length) {
      const errorComponent = refs[`${errors[0].key}${refSuffix}`]
      this.focusComponent(errorComponent)
    }
  }

  // returns 06:24 from an input like 6.4 (6.4 hours is 6 hours and 24 minutes)
  public static HoursToHourMinuteString (totalHours?: number) {
    if (!totalHours) {
      return '00:00'
    }
    const converted = Commons.HoursToHourMinute(totalHours)
    return `${Commons.padWithZeros(converted.fullHours, 2)}:${Commons.padWithZeros(Math.trunc(converted.remainingMinutes), 2)}`
  }

  // returns {fullHours:6, remainingMinutes:24} from an input like 6.4 (6.4 hours is 6 hours and 24 minutes)
  public static HoursToHourMinute (totalHours: number) {
    const fullHours = Math.trunc(totalHours)
    const remainingMinutes = Math.round((totalHours - fullHours) * 60)
    return { fullHours, remainingMinutes }
  }

  public static roundTimeFieldToNearest (time: string, vueComponent: Vue, textInputIdSuffix = '', roundToMinutes = 15) {
    const timestart = new Date(`1990-01-01T${time}`)
    const rounded = Commons.RoundTimeTo(timestart, roundToMinutes)
    const extracted = AgendaHelpers.ExtractHours(rounded, true)
    let result
    if (isNaN(extracted.hours) || isNaN(extracted.minutes)) {
      result = undefined
    } else {
      result = `${Commons.padWithZeros(extracted.hours, 2)}:${Commons.padWithZeros(extracted.minutes, 2)}`
    }

    // ugly hack, don't try this at home, this was the easiest way I found to properly make the new value appearing as desired
    const debutInputObject = vueComponent.$refs[`TextInput-${textInputIdSuffix}`] as any
    debutInputObject.internalValue = result
    return result
  }

  public static focusComponent (vueElement: any) {
    let firstInput: any = null
    if (vueElement.constructor === Array) {
      for (const ec of vueElement) {
        firstInput = this.tryToSelectFirstInput(ec)
        if (firstInput) {
          break
        }
      }
    } else {
      firstInput = this.tryToSelectFirstInput(vueElement)
    }
    if (firstInput) {
      // firstInput.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      window.scrollTo(0, firstInput.offsetTop)
      firstInput.focus()
    }
  }

  public static transformLanguages (langs: string) {
    if (!langs || Array.isArray(langs)) return langs
    const langsArr = langs.split(',').map(x => +x)
    return langsArr
  }

  public static timeMask (value) {
    const isNegative = value.startsWith('-')
    const l1 = isNegative ? 7 : 6

    let hours = [/[0-9]/, /[0-9]/]
    const minutes = [/[0-5]/, /[0-9]/]

    if (value.length === l1) {
      hours = [/[0-9]/, /[0-9]/, /[0-9]/]
    } else if (value.length > l1) {
      hours = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
    }

    if (isNegative) {
      hours.splice(0, 0, /[-]/)
    }

    return value.length > 2 ? [...hours, ':', ...minutes] : hours
  }

  public static defaultVAuthHrefErrorHandler (errorMessages: string[], e: any, callback?: (errors: string[]) => void) {
    if (e?.response?.data) {
      e.response.data.text().then(async errs => {
        const data = JSON.parse(errs)
        // since the error handling is a bit short circuit by v-auth-href, we have to build the AxiosResponse object manually
        let response: AxiosResponse
        if (data.status) {
          response = {
            config: {},
            data: data,
            headers: undefined,
            status: errs.status,
            statusText: ''
          }
        } else {
          response = {
            config: {},
            data: data,
            headers: undefined,
            status: 400,
            statusText: ''
          }
        }
        const res = await ErrorService.handleError(response)
        Commons.pushErrorMessages(errorMessages, res)
        if (callback) {
          callback(res.errors)
        }
      })
    }
  }

  private static pushErrorMessages (errorMessages: string[], res: {errors: any[]; title: string}) {
    errorMessages.length = 0
    for (let i = 0; i < res.errors.length; ++i) {
      errorMessages.push(res.errors[i])
    }
  }

  private static isIE () {
    const ua = window.navigator.userAgent // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE ') // IE 10 or older
    const trident = ua.indexOf('Trident/') // IE 11

    return (msie > 0 || trident > 0)
  }

  private static replaceDiacritics (str: string) {
    let s = str
    if (this.isIE()) {
      const diacritics = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g // C, c
      ]

      const chars = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c']

      for (let i = 0; i < diacritics.length; i++) {
        s = s.replace(diacritics[i], chars[i])
      }
    } else {
      s = s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
    return s
  }

  // This function performs the default highlighting transformation on the patient match of an autocomplete.
  public static autocompleteDisplayPatientDefaultHighlightMatch (match: string) {
    return '<span class="v-list-item__mask">' + match + '</span>'
  }

  public static autocompleteDisplayPatient (item: dossierPatientModel, val: string, autocompleteHighlightMatch: (s: string) => string = Commons.autocompleteDisplayPatientDefaultHighlightMatch) {
    const indices: number[] = []
    let itemText = item.fullName
    if (parseInt(val)) {
      return `${itemText} (${Commons.TransformDateFormat(item.dob)} - <i>${autocompleteHighlightMatch(item.patientCode.toString())}</i>)`
    }
    if (val) {
      const matcher = new RegExp(this.replaceDiacritics(val), 'ig')
      const accentsFreeText = this.replaceDiacritics(itemText)
      let result: RegExpExecArray|null
      while ((result = matcher.exec(accentsFreeText))) {
        indices.push(result.index)
      }
    }
    const highlightLength = autocompleteHighlightMatch('').length

    if (indices.length > 0) {
      const inputLength = val.length
      for (let i = 0; i < indices.length; ++i) {
        const index = indices[i] + i * highlightLength
        itemText = itemText.substr(0, index) + autocompleteHighlightMatch(itemText.substr(index, inputLength)) + itemText.substring(index + inputLength, itemText.length)
      }
    }
    return `${itemText} (${Commons.TransformDateFormat(item.dob)} - <i>${item.patientCode}</i>)`
  }

  public static getDaysInMonth (month: number, year: number) {
    return new Date(year, month + 1, 0).getDate()
  }

  public static checkSoinTypesMatch (a: number[], b: number[]) {
    if (a.length === b.length) {
      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) {
          return false
        }
      }
      return true
    }
    return false
  }

  public static sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
