export const headersItems = [
  {
    text: 'Nom',
    align: 'start',
    value: 'fullName'
  },
  { text: 'Login (Email)', value: 'email' },
  { text: 'Titre', value: 'title' },
  { text: 'Fonction', value: 'function' },
  { text: 'Actif', value: 'isEnabled' },
  { text: 'Groupe(s)', value: 'groupIdList' },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right',
    width: '10%'
  }
]

export const roomHeadersItems = [
  { text: 'Site', value: 'site' },
  { text: 'Nom', value: 'name' },
  { text: 'Types de soins', value: 'soinsTypeIds', sortable: false },
  { text: 'Heures d\'ouverture', value: 'openTime', sortable: false },
  { text: 'Actif', value: 'isActive' },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]

export const soinsTypes = [
  { text: 'Diabétique', value: 1, short: 'diab' },
  { text: 'Diététique', value: 2, short: 'diet' },
  { text: 'Pré-diabète', value: 3, short: 'prediab' },
  { text: 'Pédiatrie', value: 4, short: 'pedia' },
  { text: 'Soins des pieds', value: 5, short: 'podo' }
]

export const siteItems = [
  { site: 'Fribourg', siteId: 1 },
  { site: 'Morat', siteId: 2 },
  { site: 'Riaz', siteId: 3 },
  { site: 'Guin', siteId: 4 },
  { site: 'Billens', siteId: 5 }
]

export function getSoinName (consultationTypeId) {
  return soinsTypes.find(s => s.value === consultationTypeId)?.text
}

export function getSoinNameShort (consultationTypeId) {
  return soinsTypes.find(s => s.value === consultationTypeId)?.short
}

export const roomPlanningPeriodHeadersItems = [
  { text: 'Début', value: 'dateRange.from' },
  { text: 'Fin', value: 'dateRange.to' },
  { text: 'Description', value: 'description' },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]

export const roomPlanningHeadersItems = [
  { text: 'Site', value: 'site', sortable: false },
  { text: 'Salle', value: 'salle', sortable: false },
  { text: 'Lundi', value: 'lu', sortable: false },
  { text: 'Mardi', value: 'ma', sortable: false },
  { text: 'Mercredi', value: 'me', sortable: false },
  { text: 'Jeudi', value: 'je', sortable: false },
  { text: 'Vendredi', value: 've', sortable: false }
]

export const calendarColors = ['#FFA4A4', '#89E5CB', '#FFC99C', '#A2EBFC', '#AFABEF', '#FFEA85', '#D7DDE0']

export const calendarColorsInexcuse = ['#FFDBDB', '#D0F5EA', '#FFE9D7', '#DAF7FE', '#DFDDF9', '#FFF7CE', '#EFF1F3']
