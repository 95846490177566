import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

import { AuthService } from '@/services/auth-service'

Vue.use(VueRouter)
const authService = AuthService.getInstance()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const loggedIn = authService.isAuthenticated()
  if (isPublic) {
    next()
  } else if (loggedIn) {
    if (to.meta?.role) {
      // if inrole next else route to denied
      next()
    } else {
      next()
    }
  } else if (!isPublic && !loggedIn) {
    await authService.login(to.path)
  }
})

export default router
