/* eslint-disable @typescript-eslint/camelcase */
import { BehaviorSubject } from 'rxjs'
import http from '@/http-client'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ConsultationModel, EvaluationReportModel } from '@/models/consultation-model'
import { AnamneseSuiviModel } from '@/models/anamneseSuivi-model'
import { PsychosocialAdviceModel } from '@/models/psychosocial-model'
import { HistoirePonderaleModel, RepasModel } from '@/models/anamnese-alimentaire-model'
import { DossierAssurance } from '@/views/Patients/Static'
import { AssuranceHistoryRequest, AssuranceHistoryResponse } from '@/models/insurances-model'

export class PatientService {
  private _consultations: ConsultationModel[] = []
  private static instance: PatientService
  private _patientsSource = new BehaviorSubject<any[]>([])
  patients$ = this._patientsSource.asObservable()

  private _intervenantSource = new BehaviorSubject<any[]>([])
  intervenants$ = this._intervenantSource.asObservable()

  private _assuranceSource = new BehaviorSubject<any[]>([])
  assuranceList$ = this._assuranceSource.asObservable()

  private _dossierSelectedSource = new BehaviorSubject<any>({})
  dossierSelected$ = this._dossierSelectedSource.asObservable()

  private _consultationSelectedSource = new BehaviorSubject<ConsultationModel>({})
  consultationSelected$ = this._consultationSelectedSource.asObservable()

  private _consultationSource = new BehaviorSubject<ConsultationModel[]>([])
  consultations$ = this._consultationSource.asObservable()

  private _evaluationReportModelSelectedSource = new BehaviorSubject<EvaluationReportModel>({})
  evaluationReportModelSelected$ = this._evaluationReportModelSelectedSource.asObservable()

  constructor () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.updateDossierSelected(JSON.parse(cachedDossier))
    }
    this.getAllintervenants()
  }

  public static getInstance (): PatientService {
    if (!PatientService.instance) {
      PatientService.instance = new PatientService()
    }
    return PatientService.instance
  }

  getAllPatients () {
    http.get('/dossierPatient/dossiers?limit=-1').then(res => {
      this._patientsSource.next(res.data)
    })
  }

  getAllintervenants () {
    http.get('/account/intervenents').then(res => {
      this._intervenantSource.next(res.data)
    })
  }

  getAllAssurance () {
    http.get('/dossierPatient/assurance').then(res => {
      this._assuranceSource.next(res.data)
    })
  }

  async getAllAssuranceHistory (dossierId: string) {
    const response = await http.get(`/dossierPatient/assuranceHistory/${dossierId}`)
    return response.data as AssuranceHistoryResponse[]
  }

  async addUpdateInsuranceHistory (dossierId: string, assuranceData: AssuranceHistoryRequest) {
    return await http.put(`/dossierPatient/assuranceHistory/${dossierId}`, assuranceData)
  }

  async deleteInsuranceHistory (dossierId: string, id: number) {
    return await http.delete(`/dossierPatient/assuranceHistory/${dossierId}/${id}`)
  }

  updateDossierSelected (dossier: any) {
    this._dossierSelectedSource.next(dossier)
    sessionStorage.setItem('selectedDossier', JSON.stringify(dossier))
  }

  updateConsultationSelected (consultation: ConsultationModel) {
    this._consultationSelectedSource.next(consultation)
  }

  updateEvaluationReportModelSelected (evaluationReportModel: EvaluationReportModel) {
    this._evaluationReportModelSelectedSource.next(evaluationReportModel)
  }

  async getPatients (query?: string): Promise<dossierPatientModel[]> {
    let q = '/dossierPatient/dossiers?'
    q = query?.length ? q.concat(query) : q.concat('limit=-1')
    const patients = await http.get(q)
    return patients.data
  }

  async getActivePatients (search: string): Promise<dossierPatientModel[]> {
    const q = parseInt(search)
      ? `/dossierPatient/dossiers?limit=-1&search=isActive eq ${true}&search=patientCode eq ${encodeURI(parseInt(search).toString())}`
      : `/dossierPatient/dossiers?limit=-1&search=isActive eq ${true}&search=prenom,nom has ${encodeURI(search)}`
    const patients = await http.get(q)
    return patients.data
  }

  async getInitialPatient (patientCode: number): Promise<dossierPatientModel[]> {
    const q = `/dossierPatient/dossiers?limit=1&search=isActive eq ${true}&search=patientCode eq ${encodeURI(patientCode.toString())}`
    const patients = await http.get(q)
    return patients.data
  }

  public async getDossierPatientById (patientId: string) {
    const response = await http.get(`/dossierPatient/dossier/${patientId}`)
    return (response.data) as dossierPatientModel
  }

  async createPatientInfo (infoData: any) {
    if (!Array.isArray(infoData.languagesEnumIdList)) {
      infoData.languagesEnumIdList = infoData.languagesEnumIdList ? infoData.languagesEnumIdList.split(',') : infoData.languagesEnumIdList
    }
    return await http.post('/dossierPatient/dossier', infoData)
  }

  async getDossierAssuranceInfo (dossierId: string, date?: string) {
    const params = new URLSearchParams()
    if (date) {
      params.append('date', date)
    }
    const response = await http.get(`/dossierPatient/dossierAssurance/${dossierId}`, {
      params
    })
    if (response.status === 204) {
      return {
        assuranceId: 0,
        assuranceNumber: '',
        dossierPatientId: dossierId
      }
    }
    return (response.data) as DossierAssurance
  }

  async upsetReseauInfo (dossierId: string, reseauData: any) {
    reseauData.dossierPatientId = dossierId
    return await http.put(`/dossierPatient/dossierReseau/${dossierId}`, reseauData)
  }

  async getDossierReseauInfo (dossierId: string) {
    return await http.get(`/dossierPatient/dossierReseau/${dossierId}`)
  }

  async deleteDossierReseauInfo (reseauId: string, linkedId = 0) {
    if (linkedId) {
      return await http.delete(`/dossierPatient/dossierReseau/professionnel/${reseauId}/${linkedId}`)
    } else {
      return await http.delete(`/dossierPatient/dossierReseau/${reseauId}`)
    }
  }

  async initDossierCollections (dossierId: string) {
    if (!this._consultations.length && dossierId) {
      await this.getDossierConsultations(dossierId)
    }
  }

  async getDossierConsultations (dossierId: string) {
    const response = await http.get(`/dossierPatient/consultations/${dossierId}`)
    this._consultationSource.next(response.data)
  }

  async AddDossierConsultations (dossierId: string, consultationData: ConsultationModel) {
    consultationData.dossierId = dossierId
    return await http.post(`/dossierPatient/consultations/${dossierId}`, consultationData)
  }

  async deleteDossierConsultation (consultationId: string | undefined) {
    return await http.delete(`/dossierPatient/consultations/${consultationId}`)
  }

  async getDossierAnamnese (dossierId: string) {
    const response = await http.get(`/dossierPatient/anamnese/${dossierId}`)
    return (response.data)
  }

  async getProfessionalsByType (professoinTypeId: number): Promise<any[]> {
    const response = await http.get(`/dossierPatient/professionals/${professoinTypeId}`)
    return (response.data)
  }

  async AddDossierAnamnese (dossierId: string, anamneseData: any) {
    anamneseData.dossierId = dossierId
    return await http.post(`/dossierPatient/anamnese/${dossierId}`, anamneseData)
  }

  async AddDossierAnamneseSuivi (dossierId: string, anamneseSuiviItem: AnamneseSuiviModel) {
    anamneseSuiviItem.anamneseId = dossierId
    return await http.post(`/dossierPatient/anamnese/suivi/${dossierId}`, anamneseSuiviItem)
  }

  async GetAllMedicines () {
    const meds = await http.get('/dossierPatient/medicines')
    return meds.data
  }

  async DeleteDossierAnamneseSuivi (suiviId: string) {
    return await http.delete(`/dossierPatient/anamnese/suivi/${suiviId}`)
  }

  async GetDossierPsychosocial (dossierId: string) {
    const response = await http.get(`/dossierPatient/psychosocial/${dossierId}`)
    return (response.data)
  }

  async AddDossierAnamneseHtml (dossierId: string, anamneseData: any) {
    anamneseData.dossierId = dossierId
    return await http.post(`/dossierPatient/anamnese/html/${dossierId}`, anamneseData)
  }

  async AddDossierAdvice (dossierId: string, adviceItem: PsychosocialAdviceModel) {
    adviceItem.patientId = dossierId
    return await http.post(`/dossierPatient/psychosocial/conseil/${dossierId}`, adviceItem)
  }

  async DeleteDossierAdvice (adviceId: string) {
    return await http.delete(`/dossierPatient/psychosocial/conseil/${adviceId}`)
  }

  // Histore ponderale
  async getHistoirePonderale (dossierId: string) {
    const response = await http.get(`/dossierPatient/histoire-ponderale/${dossierId}`)
    return (response.data) as HistoirePonderaleModel[]
  }

  async addObservation (dossierId: string, observation: HistoirePonderaleModel) {
    observation.dossierId = dossierId
    return await http.post(`/dossierPatient/histoire-ponderale/${dossierId}`, observation)
  }

  async deleteObservation (observationId: string) {
    return await http.delete(`/dossierPatient/histoire-ponderale/${observationId}`)
  }

  // Repas
  async getRepasItems (dossierId: string) {
    const response = await http.get(`/dossierPatient/repas/${dossierId}`)
    return (response.data) as RepasModel[]
  }

  async addRepasItem (dossierId: string, repas: RepasModel) {
    repas.dossierId = dossierId
    return await http.post(`/dossierPatient/repas/${dossierId}`, repas)
  }

  async deleteRepas (repasId: string) {
    return await http.delete(`/dossierPatient/repas/${repasId}`)
  }

  public async getDefaultEvaluationInfirmiereId () {
    const response = await http.get(`/parameter/defaultEvaluationInfirmiereId`)
    return (response.data) as string
  }

  public async updateDossierSelectedByDossierId (dossierId: string) {
    const dossier = await this.getDossierPatientById(dossierId)
    this._dossierSelectedSource.next(dossier)
    sessionStorage.setItem('selectedDossier', JSON.stringify(dossier))
  }
}
