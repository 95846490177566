


















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import Item from '@/components/shared/DraweItem/Item.vue'
import ItemGroup from '@/components/shared/ItemGroup.vue'
import { LayoutService } from '@/services/layout-service'
import { DrawerItem } from './Models/drawer-model'

@Component({
  components: {
    Item,
    ItemGroup
  }
})
export default class NavDrawer extends Vue {
  private items: DrawerItem[] = []
  private layoutService = LayoutService.getInstance()

  drawer = false

  public mounted () {
    this.layoutService.drawerState$.subscribe(state => {
      this.drawer = state
    })

    this.layoutService.drawerItems$.subscribe(items => {
      this.items = items
    })
  }

  get computedItems () {
    return this.items
  }

  /** toggleDrawer **/
  @Prop() value!: boolean
  public toggleDrawer () {
    this.value = !this.value
    this.layoutService.updateState()
  }
}
