import { DrawerItem } from '@/components/shared/Models/drawer-model'

/* eslint-disable */
export const DossierNavItems: DrawerItem[] = [
  {
    icon: 'mdi-plus-circle-outline',
    title: 'Ajouter un nouveau dossier',
    to: '/patient/dossier/create?mode=new',
    class: 'button btn-red'
  },
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  }
]
export const DossierNavItemsNoCreate: DrawerItem[] = [
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  }
]

export const FullNavItems: DrawerItem[] = [
  {
    icon: 'mdi-plus-circle-outline',
    title: 'Ajouter un nouveau dossier',
    to: '/patient/dossier/create?mode=new',
    class: 'button btn-red'
  },
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  },
  {
    icon: 'mdi-account-outline',
    title: '',
    to: '',
    class: 'title',
    infoImportantCount: 0,
    appointmentsWithImportantInfoCount: 0
  },
  {
    icon: '',
    title: 'Dossier Patient',
    to: '/patient/dossier',
    level: 2
  },
  {
    icon: '',
    title: 'Dossier Diabète',
    to: '/patient/dossierDiab',
    level: 2
  },
  {
    title: 'Vue d\'ensemble',
    to: '',
    level: 2,
    group: 'consultation',
    children: [
      {
        icon: '',
        title: 'Pré-diabétique',
        to: '/patient/consultation/pre-diabetique',
        level: 3
      },
      {
        icon: '',
        title: 'Diabétique',
        to: '/patient/consultation/diabetique',
        level: 3
      },
      {
        icon: '',
        title: 'Diététique',
        to: '/patient/consultation/dietetique',
        level: 3
      },
      {
        icon: '',
        title: 'Podologique',
        to: '/patient/consultation/podo',
        level: 3
      },
      {
        icon: '',
        title: 'Pédiatrique',
        to: '/patient/consultation/pediatrique',
        level: 3
      }
    ]
  },
  {
    icon: '',
    title: 'Rendez-vous',
    to: '/patient/rdv',
    level: 2
  },
  {
    icon: '',
    title: 'Prescriptions',
    to: '/patient/prescriptions',
    level: 2
  },
  {
    icon: '',
    title: 'Matériel',
    to: '/patient/materiel',
    level: 2
  },
  {
    icon: '',
    title: 'Documents divers',
    to: '/patient/document-divers',
    level: 2
  },
  {
    icon: '',
    title: 'Documents administratifs',
    to: '/patient/document-admin',
    level: 2
  }
]

export const FullNavItemsNoCreate: DrawerItem[] = [
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  },
  {
    icon: 'mdi-account-outline',
    title: '',
    to: '',
    class: 'title',
    infoImportantCount: 0,
    appointmentsWithImportantInfoCount: 0
  },
  {
    icon: '',
    title: 'Dossier Patient',
    to: '/patient/dossier',
    level: 2
  },
  {
    icon: '',
    title: 'Dossier Diabète',
    to: '/patient/dossierDiab',
    level: 2
  },
  {
    title: 'Vue d\'ensemble',
    to: '',
    level: 2,
    group: 'consultation',
    children: [
      {
        icon: '',
        title: 'Pré-diabétique',
        to: '/patient/consultation/pre-diabetique',
        level: 3
      },
      {
        icon: '',
        title: 'Diabétique',
        to: '/patient/consultation/diabetique',
        level: 3
      },
      {
        icon: '',
        title: 'Diététique',
        to: '/patient/consultation/dietetique',
        level: 3
      },
      {
        icon: '',
        title: 'Podologique',
        to: '/patient/consultation/podo',
        level: 3
      },
      {
        icon: '',
        title: 'Pédiatrique',
        to: '/patient/consultation/pediatrique',
        level: 3
      }
    ]
  },
  {
    icon: '',
    title: 'Rendez-vous',
    to: '/patient/rdv',
    level: 2
  },
  {
    icon: '',
    title: 'Prescriptions',
    to: '/patient/prescriptions',
    level: 2
  },
  {
    icon: '',
    title: 'Matériel',
    to: '/patient/materiel',
    level: 2
  },
  {
    icon: '',
    title: 'Documents divers',
    to: '/patient/document-divers',
    level: 2
  },
  {
    icon: '',
    title: 'Documents administratifs',
    to: '/patient/document-admin',
    level: 2
  }
]

export const FullNavItemsNoOverview: DrawerItem[] = [
  {
    icon: 'mdi-plus-circle-outline',
    title: 'Ajouter un nouveau dossier',
    to: '/patient/dossier/create?mode=new',
    class: 'button btn-red'
  },
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  },
  {
    icon: 'mdi-account-outline',
    title: '',
    to: '',
    class: 'title',
    infoImportantCount: 0,
    appointmentsWithImportantInfoCount: 0
  },
  {
    icon: '',
    title: 'Dossier Patient',
    to: '/patient/dossier',
    level: 2
  },
  {
    icon: '',
    title: 'Dossier Diabète',
    to: '/patient/dossierDiab',
    level: 2
  },
  {
    icon: '',
    title: 'Rendez-vous',
    to: '/patient/rdv',
    level: 2
  },
  {
    icon: '',
    title: 'Prescriptions',
    to: '/patient/prescriptions',
    level: 2
  },
  {
    icon: '',
    title: 'Matériel',
    to: '/patient/materiel',
    level: 2
  },
  {
    icon: '',
    title: 'Documents divers',
    to: '/patient/document-divers',
    level: 2
  },
  {
    icon: '',
    title: 'Documents administratifs',
    to: '/patient/document-admin',
    level: 2
  }
]

export const FullNavItemsNoCreateNoOverview: DrawerItem[] = [
  {
    icon: 'mdi-account-search-outline',
    title: 'Rechercher un dossier',
    to: '/patient'
  },
  {
    icon: 'mdi-account-outline',
    title: '',
    to: '',
    class: 'title',
    infoImportantCount: 0,
    appointmentsWithImportantInfoCount: 0
  },
  {
    icon: '',
    title: 'Dossier Patient',
    to: '/patient/dossier',
    level: 2
  },
  {
    icon: '',
    title: 'Dossier Diabète',
    to: '/patient/dossierDiab',
    level: 2
  },
  {
    icon: '',
    title: 'Rendez-vous',
    to: '/patient/rdv',
    level: 2
  },
  {
    icon: '',
    title: 'Prescriptions',
    to: '/patient/prescriptions',
    level: 2
  },
  {
    icon: '',
    title: 'Matériel',
    to: '/patient/materiel',
    level: 2
  },
  {
    icon: '',
    title: 'Documents divers',
    to: '/patient/document-divers',
    level: 2
  },
  {
    icon: '',
    title: 'Documents administratifs',
    to: '/patient/document-admin',
    level: 2
  }
]

export const DossierHeaders = [
    {
      text: '',
      value: 'warnings',
      sortable: false
		},
		{
			text: 'N°',
			value: 'patientCode'
		},
    {
      text: 'Nom',
      value: 'fullName'
    },
    {
      text: 'Naissance',
      value: 'dob'
    },
    {
      text: 'Localité',
      value: 'city'
    },
    {
      text: 'District',
      value: 'district'
    },
    {
      text: 'Prochain RDV',
      value: 'nextRdv'
    },
    {
      text: 'Intervenant',
      value: 'professional',
      sortable: false
    },
    {
      text: 'Type de consultation',
      value: 'consultationType',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right'
    }
]

export const DossierTabs = [
  'Données personnelles',
  'Données administratives',
  'Données assurances',
  'Réseau familial et professionnel'
]

export interface DossierAssurance {
  assuranceId?: number;
  assuranceNumber?: string;
}

export const reseaux: {
  mode: string;
  title: string;
  headers: { text: string; value: string; sortable?: boolean; align?: string }[];
  data?: any []
}[] = [
  {
    mode: 'fam',
    title: 'Réseau familial',
    headers: [
      { text: 'Lien', value: 'relationship' },
      { text: 'Représentant légal', value: 'legalRepresentation' },
      { text: 'Nom', value: 'nom' },
      { text: 'Téléphone', value: 'telephone' },
      { text: 'Contact en cas d\'urgence', value: 'isEmergencyContact' },
      { text: 'Remarques', value: 'remark' },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
  },
  {
    mode: 'pro',
    title: 'Réseau professionnel',
    headers: [
      { text: 'Type', value: 'reseauMedicalExtra.type' },
      { text: 'Specialité', value: 'reseauMedicalExtra.specialite' },
      { text: 'Nom', value: 'nom' },
      { text: 'Téléphone', value: 'telephone' },
      // { text: 'Fonction', value: 'reseauMedicalExtra.fonction' },
      // { text: 'Collaboration médicale', value: 'reseauMedicalExtra.collaboration' },
      { text: 'Remarques', value: 'remark' },
      { text: 'Actif', value: 'reseauMedicalExtra.isActive' },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
  }
]

export const headers = [
  {
    text: 'Date',
    value: 'date'
  },
  {
    text: 'Numéro',
    value: 'title'
  },
  {
    text: 'Genre',
    value: 'genre'
  },
  {
    text: 'Objectifs',
    value: 'objectif'
  },
  {
    text: 'Créé par',
    value: 'createdBy'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]

export const PrescriptionHeader = [
  {
    text: 'Date',
    value: 'date'
  },
  {
    text: 'Médecin',
    value: 'prescriptionDoctorName'
  },
  {
    text: 'Statut',
    value: 'statusId'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]

export const PrescriptionSearchHeader = [
  {
    text: 'Patient',
    value: 'patientName'
  },
  {
    text: 'Date de début',
    value: 'dateStart'
  },
  {
    text: 'Statut',
    value: 'status'
  },
  {
    text: 'Médecin',
    value: 'prescriptionDoctorName'
  },
  {
    text: 'District',
    value: 'district'
  },
  {
    text: 'Site',
    value: 'sites',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]

export const EntourageHeaders = [
  {
    text: 'Date',
    value: 'date'
  },
  {
    text: 'Créé par',
    value: 'createdBy'
  },
  {
    text: '',
    value: 'relative',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]


export const PrestationReportHeaders = [
  {
    text: 'Type',
    value: 'type'
  },
  {
    text: 'Date',
    value: 'createdAt'
  },
  {
    text: 'Médecin',
    value: 'medecineName'
  },
  {
    text: 'Créé par',
    value: 'createdBy'
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'right'
  }
]
