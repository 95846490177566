import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { getSoinName } from '@/views/Administration/Constants'
import Commons from '../../Helpers/commons'

@Component({
  components: {
  }
})
export default class ImportantAppointmentDialog extends Vue {
  @Prop({ default: false })
  public show!: boolean

  public dossier: dossierPatientModel | null = null

  @Watch('show')
  public async onChanged (val: boolean) {
    if (val) {
      this.dossier = Commons.getDossierOrRedirect(this.$router)
    }
  }

  public async mounted () {
    this.dossier = Commons.getDossierOrRedirect(this.$router)
  }

  get hasItems () {
    if (this.dossier && this.dossier.warnings) {
      return this.dossier.warnings.length > 0
    }
    return false
  }

  public closeDialog () {
    this.$emit('close')
  }

  public soinName (consultationTypeId) {
    return getSoinName(consultationTypeId)
  }
}
