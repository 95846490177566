import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Commons from '../Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { AppointmentForConsultationResponseModel } from '@/models/agenda-model'
import { AuthService } from '@/services/auth-service'
import { AppUser } from '@/models/app-user-dto'
import { ErrorService } from '@/services/error.service'

export type DocumentsFetcherFunction = (id: string) => Promise<any>

@Component({
  components: {
  }
})
export default class AppointmentSelector extends Vue {
  private userService = AuthService.getInstance()

  @Prop({ default: '' })
  public value!: string

  @Prop({ default: '' })
  public rules!: string

  @Prop({ default: '' })
  public name!: string

  @Prop({ default: '' })
  public extraClasses!: string

  @Prop({ default: undefined })
  public consultationId: any

  @Prop({ default: 0 })
  public consultationTypeId!: number

  @Prop({ default: false })
  public readyAppointments!: boolean

  public dossier: dossierPatientModel | null = null

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public appointments!: AppointmentForConsultationResponseModel[]

  @Prop({ type: Array, required: false, default: () => { return [] } })
  public errorMessages!: string[]

  private allInfirmieres: AppUser[] = []

  public selectedAppointment: number|null = null

  private readyInfirmieres = false
  public isLoading = true

  public async mounted () {
    this.selectedAppointment = parseInt(this.value) || null
    this.isLoading = true
    this.dossier = Commons.getDossierOrRedirect(this.$router)
    this.getAllInfirmiereUsers()
  }

  public onBlur (e) {
    this.$emit('blur', e)
  }

  @Watch('selectedAppointment') onChange (value: number) {
    this.$emit('input', value)
  }

  @Watch('value') onValueChanges (val: number) {
    if (val !== this.selectedAppointment) {
      this.selectedAppointment = val
    }
  }

  private updateLoadingState () {
    this.isLoading = !this.readyInfirmieres && !this.readyAppointments
  }

  @Watch('readyAppointments')
  public readyAppointmentsChanged () {
    this.updateLoadingState()
    // if we are creating and there is only one appointment, pre-select it
    if (this.consultationId === undefined && this.appointments.length === 1) {
      this.selectedAppointment = this.appointments[0].id
    }
  }

  @Watch('readyInfirmieres')
  public readyInfirmieresChanged () {
    this.updateLoadingState()
  }

  public getAllInfirmiereUsers () {
    this.readyInfirmieres = false
    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    }).catch(async (errs) => {
      await ErrorService.handleError(errs)
    }).finally(() => {
      this.readyInfirmieres = true
    })
  }

  public nurseName (nurseId) {
    if (this.readyInfirmieres) {
      const infirmiere = Commons.FindNurse(this.allInfirmieres, nurseId)
      if (infirmiere) {
        return infirmiere.fullName
      }
    }
    return ''
  }

  get isEditMode () {
    return this.consultationId !== undefined
  }
}
